import { CosmeticDefinition, cosmeticDefinitionById } from 'leetify-cosmetics-dtos/cosmetic-definitions';
import { CosmeticId, CosmeticImageType, CosmeticImageUrl } from 'leetify-cosmetics-dtos/enums';
import { emptySvgDataUrl } from 'leetify-cosmetics-dtos/constants';
import { environment } from 'src/environments/environment';

export class CosmeticsHelper {
	protected static readonly baseUrl = `${environment.staticAssetsUrl}/cosmetics`;

	public static getImageUrl(cosmeticId: CosmeticId, imageType: CosmeticImageType, url: CosmeticImageUrl): string {
		const cosmetic = cosmeticDefinitionById[cosmeticId];
		if (!cosmetic || !this.doesCosmeticHaveImageType(cosmetic, imageType)) return emptySvgDataUrl;

		return cosmetic.urlOverrides?.[imageType]?.[url]
			|| `${this.baseUrl}/${cosmeticId}/${imageType}/${url}`;
	}

	public static async precacheImage(cosmeticId: CosmeticId, imageType: CosmeticImageType, url: CosmeticImageUrl): Promise<void> {
		const imageUrl = this.getImageUrl(cosmeticId, imageType, url);
		return this.precacheImageUrl(imageUrl);
	}

	protected static async precacheImageUrl(url: string): Promise<void> {
		if (typeof document === 'undefined') return;

		const el = document.createElement('img');
		el.src = url;
	}

	protected static doesCosmeticHaveImageType(cosmetic: CosmeticDefinition, imageType: CosmeticImageType): boolean {
		switch (imageType) {
			case CosmeticImageType.AVATAR_BORDER: return !!cosmetic?.isAvatarBorder;

			case CosmeticImageType.BANNER_BORDER_FULL:
			case CosmeticImageType.BANNER_BORDER_PREVIEW:
			case CosmeticImageType.MINI_PROFILE_BORDER_FULL:
				return !!cosmetic?.isBannerBorder;

			default: return false;
		}
	}
}
