import { emptySvgDataUrl } from './constants';
import { CosmeticId, CosmeticImageType, CosmeticImageUrl } from './enums';

export interface CosmeticDefinition {
	id: CosmeticId;

	isAvatarBorder?: boolean;
	isBannerBorder?: boolean;
	isNull?: boolean;

	/** If an image url does not use the same path and file name as others, define it here. Use this e.g. for browser cache busting when updating an image. */
	urlOverrides?: {
		[imageType in CosmeticImageType]?: {
			[url in CosmeticImageUrl]?: string;
		};
	};
}

// TODO we may end up needing to move this into separate classes (like with skills back in the day), but for now it's fine as it is
export const cosmeticDefinitionById: { [cosmeticId in CosmeticId]: CosmeticDefinition } = {
	[CosmeticId.NONE]: {
		id: CosmeticId.NONE,
		isAvatarBorder: true,
		isBannerBorder: true,
		isNull: true,
		urlOverrides: Object.fromEntries(Object.values(CosmeticImageType).map((type) => [type, Object.fromEntries(Object.values(CosmeticImageUrl).map((url) => [url, emptySvgDataUrl]))])),
	},

	[CosmeticId.ANCIENT]: { id: CosmeticId.ANCIENT, isAvatarBorder: true, isBannerBorder: true },
	[CosmeticId.ANUBIS]: { id: CosmeticId.ANUBIS, isAvatarBorder: true, isBannerBorder: true },
	[CosmeticId.ASTRAL]: { id: CosmeticId.ASTRAL, isAvatarBorder: true, isBannerBorder: true },
	[CosmeticId.CROSSHAIR]: { id: CosmeticId.CROSSHAIR, isAvatarBorder: true },
	[CosmeticId.FLORAL]: { id: CosmeticId.FLORAL, isAvatarBorder: true, isBannerBorder: true },
	[CosmeticId.RAINBOW]: { id: CosmeticId.RAINBOW, isAvatarBorder: true, isBannerBorder: true },
	[CosmeticId.SMOKE]: { id: CosmeticId.SMOKE, isAvatarBorder: true, isBannerBorder: true },
};

export const allCosmeticDefinitions: CosmeticDefinition[] = Object.values(cosmeticDefinitionById);
