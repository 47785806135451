import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FollowNotification } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';
import { FollowService } from 'src/components/services/follow.service';
import { NotificationsHelper } from 'src/components/helpers/notifications.helper';
import { ViewHelper } from 'src/components/helpers/view.helper';

@Component({
	selector: 'leetify-common-follow-request',
	templateUrl: './follow-request.component.html',
	styleUrls: ['./follow-request.component.scss'],
})
export class CommonFollowRequestComponent {
	@Output() public readonly dismiss = new EventEmitter<void>();

	public readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;

	@Input() public followRequest: FollowNotification;
	@Input() public isRead: boolean;
	@Input() public createdAt: Date;

	protected followBack = false;
	protected followBackSent = false;

	public constructor(
		protected readonly followService: FollowService,
	) {
		//
	}

	protected isNameTooLong() {
		if (this.followRequest.nickname) return this.followRequest.nickname.length > 17;
		return false;
	}

	protected calculateMinutesAgo() {
		return NotificationsHelper.getNotificationTime(new Date(this.createdAt));
	}

	protected async ignoreRequest() {
		await this.followService.ignoreRequest(this.followRequest.sender_id);
		this.followRequest.status = 'ignored';
	}

	protected async acceptRequest() {
		await this.followService.acceptRequest(this.followRequest.sender_id);
		this.followRequest.status = 'accepted';

		// if user doesn't already follow the user who sent the request
		if (!this.followRequest.is_followed_back) {
			this.followBack = true;
		}
	}

	protected goToProfile(senderId: string) {
		window.location.href = `${environment.csFrontendUrl}/profile/${senderId}`;
	}

	protected async sendFollowRequestBack() {
		await this.followService.sendRequest(this.followRequest.sender_id);
		this.followBackSent = true;
	}
}
