export class ViewHelper {
	public static readonly PROFILE_PICTURE_FALLBACK = 'assets/images/anon.png';

	public static onProfilePictureErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.PROFILE_PICTURE_FALLBACK);
	}

	public static onImageErrored(e: ErrorEvent, fallback: string): void {
		if (e.target && e.target instanceof HTMLImageElement && e.target.getAttribute('src') !== fallback) {
			e.target.setAttribute('src', fallback);
		}
	}
}
