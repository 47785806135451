export enum GameD2dReplayErrorCode {
	PARSE_ERROR = 'parse_error',
	STATS_ERROR = 'stats_error',
	TIMEOUT = 'timeout',
	UNKNOWN_ERROR = 'unknown_error',
}

export enum GameD2dReplayStatus {
	DOWNLOADING = 'downloading',
	ERROR = 'error',
	PROCESSING = 'processing',
	QUEUED = 'queued',
	READY = 'ready',
	SCHEDULED = 'scheduled',
}

export enum GameFreeD2dReplayRequestErrorCode {
	COULD_NOT_REQUEST = 'could_not_request',
	DID_NOT_PLAY_IN_GAME = 'did_not_play_in_game',
	GAME_TOO_OLD = 'game_too_old',
	NO_STEAM_ACCOUNT_CONNECTED = 'no_steam_account_connected',
	NOT_ALLOWED_FOR_GAME = 'not_allowed_for_game',
	NOT_ENOUGH_CREDITS = 'not_enough_credits',
	USER_IS_PRO_PLAN = 'user_is_pro_plan',
}

export enum GameFreeD2dReplayStatus {
	ERROR = 'error',
	READY = 'ready',
	REQUESTED = 'requested',
}
