import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CriticalWarningStyle } from 'leetify-shared-utils/enums';
import { CriticalWarning, Follow, Notification } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/components/services/notifications.service';
import { NotificationsHelper } from 'src/components/helpers/notifications.helper';
import { Icon } from '../../../atoms/icon/icon.component';

@Component({
	selector: 'leetify-common-notifications-component',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	host: {
		'(document:click)': 'onClick($event)',
	},
})
export class CommonNotificationsComponent implements OnChanges, OnDestroy {
	@Input() public criticalWarnings: CriticalWarning[];
	@Input() public followRequests: Follow[] = [];
	@Input() public notifications: Notification[];
	@Input() public numberOfUnreadNotifications: number;
	@Output() public readonly dropdownToggle = new EventEmitter<boolean>();

	protected readonly CriticalWarningStyle = CriticalWarningStyle;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly Icon = Icon;

	protected isActive: boolean = false;
	protected serverIconPath: string;
	protected warningIconPath: string;
	protected serverState: string;
	protected time: string;
	protected isFollowRequestsTabActive: boolean = false;
	protected isSocialNotificationsTabActive: boolean = false;
	protected isAllTabActive: boolean = true;
	protected notificationsRead: boolean = false;
	// to check only mark notifications as read on the 2nd open
	protected wereNotificationsOpened = false;
	protected followRequestsCount = 0;
	protected socialNotificationsCount = 0;
	protected allNotificationsCount = 0;

	public constructor(
		private _eref: ElementRef,
		protected notificationsService: NotificationsService,
	) {
		//
	}

	public onClick(event: MouseEvent): void {
		if(!event.composedPath().includes(this._eref.nativeElement)) {
			this.isActive = false;
		}
	}

	protected async toggleDropdown() {
		// first time opening notificationsRead will be false
		if (this.notificationsRead) {
			this.wereNotificationsOpened = true;
		}
		this.isActive = !this.isActive;
		this.dropdownToggle.emit(this.isActive);

		// mark as read
		await this.notificationsService.markNotificationsAsRead();
		this.notificationsRead = true;

		if (this.notifications) {
			this.notifications.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

			// limit to 20 notifications
			this.notifications.splice(20);

			this.followRequestsCount = this.getNumberOfSocialNotifications('follow');
			this.socialNotificationsCount = this.getNumberOfSocialNotifications('like');
			this.allNotificationsCount = this.getNumberOfSocialNotifications('all');
			this.numberOfUnreadNotifications = 0;

			// set favicon to default
			this.notificationsService.setFaviconAndTitle(this.numberOfUnreadNotifications);
		}
	}

	protected switchTab(tab: 'follow' | 'social' | 'all') {
		this.isFollowRequestsTabActive = tab === 'follow';
		this.isSocialNotificationsTabActive = tab === 'social';
		this.isAllTabActive = tab === 'all';
	}

	private getOverallState(activeCriticalWarnings: CriticalWarning[]) {
		if (activeCriticalWarnings.some((cw) => cw.style === CriticalWarningStyle.DANGER)) return CriticalWarningStyle.DANGER;
		if (activeCriticalWarnings.some((cw) => cw.style === CriticalWarningStyle.WARNING)) return CriticalWarningStyle.WARNING;
		return CriticalWarningStyle.SUCCESS;
	}

	private getServerStatusIcon(serverStatus: string) {
		if (serverStatus === CriticalWarningStyle.SUCCESS) return 'assets/icons/server-status-no-error.svg';
		if (serverStatus === CriticalWarningStyle.DANGER) return 'assets/icons/server-status-error.svg';
		if (serverStatus === CriticalWarningStyle.WARNING) return 'assets/icons/server-status-warning.svg';
		return '';
	}

	protected getServerStatusType() {
		if (this.serverState !== this.getOverallState(this.criticalWarnings)) {
			this.serverState = this.getOverallState(this.criticalWarnings);
			this.serverIconPath = this.getServerStatusIcon(this.serverState);
			this.warningIconPath = this.getWarningIcon(this.serverState);
		}

		return this.serverState;
	}

	protected getWarningIcon(serverStatus: string) {
		if (serverStatus === CriticalWarningStyle.WARNING) return 'assets/icons/warning-sign.svg';
		if (serverStatus === CriticalWarningStyle.DANGER) return 'assets/icons/error-sign.svg';
		return '';
	}

	protected isNameTooLong(username: string) {
		if (username) return username.length > 17;
		return false;
	}

	protected calculateMinutesAgo(createdAt: Date) {
		return NotificationsHelper.getNotificationTime(createdAt);
	}

	protected getNumberOfSocialNotifications(type: string) {
		let count = 0;

		if (this.notifications) {
			// sort by created at
			if (type === 'follow') {
				count = this.notifications.filter((notification) => notification.data.type === 'follow').length;
			} else if (type === 'like') {
				count = this.notifications.filter((notification) => notification.data.type === 'like').length;
			} else {
				count = this.notifications.length;
			}
		}

		return count;
	}

	protected goToPost(gameName: string, matchId: string, leetifyUserId: string) {
		window.location.href = `${environment.homeFrontendUrl}/feed/${gameName}/${matchId}/${leetifyUserId}`;
	}

	public ngOnChanges() {
		this.notificationsService.setFaviconAndTitle(this.numberOfUnreadNotifications);
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
