import { Benchmark } from '../benchmarks-repository';
import { TeamName } from '../enums';
import { ZScoreDeviationStep, ZScoreHelper } from '../z-score-helper';

export type CalculationChangeDates = {
	date: string;
	url: string;
}[];

export type PostSign = '%' | '°' | 'ms' | '$' | 'sec' | '+';

export type SkillGroupId = 'aimSkillGroup'
	| 'clutchSkillGroup'
	| 'focusAreaSkillGroup'
	| 'positioningSkillGroup'
	| 'teamplaySideGroup'
	| 'teamplaySituationGroup'
	| 'teamplaySituationPlayerGroup'
	| 'utilitySkillGroup';

export enum SkillId {
	ACCURACY = 'accuracy',
	ACCURACY_ENEMY_SPOTTED = 'accuracyEnemySpotted',
	ACCURACY_HEAD = 'accuracyHead',
	ADR = 'adr',
	ADV_PROPORTION_RATE = 'advProportionRate',
	ADV_WIN_RATE = 'advWinRate',
	AIM_RATING = 'aimRating',
	AIRBORNE_KILLS_PER_GAME = 'airborneKillsPerGame',
	BLINDED_KILLS_PER_GAME = 'blindedKillsPerGame',
	CLUTCH_1V1_RATING = 'clutch1v1Rating',
	CLUTCH_1V2_RATING = 'clutch1v2Rating',
	CLUTCH_1V3_RATING = 'clutch1v3Rating',
	CLUTCH_1V4_RATING = 'clutch1v4Rating',
	CLUTCH_1V5_RATING = 'clutch1v5Rating',
	CLUTCH_1VX_RATING = 'clutch1vXRating',
	COUNTER_STRAFING_GOOD_SHOTS_RATIO = 'counterStrafingShotsGoodRatio',
	CROSSHAIR_PLACEMENT = 'preaim',
	CT_BOMBSITE_HOLD_RATE = 'ctBombsiteHoldRate',
	CT_BOMBSITE_RETAKE_RATE = 'ctBombsiteRetakeRate',
	CT_KD_RATIO = 'ctKdRatio',
	CT_LEETIFY_RATING = 'ctLeetifyRating',
	CT_OPENING_AGGRESSION_SUCCESS_RATE = 'ctOpeningAggressionSuccessRate',
	CT_OPENING_DUEL_ATTEMPTS_PERCENTAGE = 'ctOpeningDuelAttemptsPercentage',
	CT_OPENING_DUEL_SUCCESS_PERCENTAGE = 'ctOpeningDuelSuccessPercentage',
	CT_OPENING_DUEL_TRADE_PERCENTAGE = 'ctOpeningDuelTradePercentage',
	CT_OPENING_RATING = 'ctOpeningRating',
	CT_POSITIONING_RATING = 'ctPositioningRating',
	CT_ROUND_WIN_RATE_ANCIENT = 'ctRoundWinRateAncient',
	CT_ROUND_WIN_RATE = 'ctRoundWinRate',
	CT_ROUND_WIN_RATE_DUST2 = 'ctRoundWinRateDust2',
	CT_ROUND_WIN_RATE_INFERNO = 'ctRoundWinRateInferno',
	CT_ROUND_WIN_RATE_MIRAGE = 'ctRoundWinRateMirage',
	CT_ROUND_WIN_RATE_NUKE = 'ctRoundWinRateNuke',
	CT_ROUND_WIN_RATE_OVERPASS = 'ctRoundWinRateOverpass',
	CT_ROUND_WIN_RATE_VERTIGO = 'ctRoundWinRateVertigo',
	CT_TRADED_DEATH_ATTEMPTS_PERCENTAGE = 'ctTradedDeathAttemptsPercentage',
	CT_TRADED_DEATHS_OPPORTUNITIES_PER_ROUND = 'ctTradedDeathsOpportunitiesPerRound',
	CT_TRADED_DEATHS_SUCCESS_PERCENTAGE = 'ctTradedDeathsSuccessPercentage',
	CT_TRADE_KILL_ATTEMPTS_PERCENTAGE = 'ctTradeKillAttemptsPercentage',
	CT_TRADE_KILL_OPPORTUNITIES_PER_ROUND = 'ctTradeKillOpportunitiesPerRound',
	CT_TRADE_KILLS_SUCCESS_PERCENTAGE = 'ctTradeKillsSuccessPercentage',
	DAMAGE_PER_SHOT = 'damagePerShot',
	DISADV_PROPORTION_RATE = 'disadvProportionRate',
	DISADV_WIN_RATE = 'disadvWinRate',
	FLASHBANG_HIT_FOE_AVG_DURATION = 'flashbangHitFoeAvgDuration',
	FLASHBANG_HIT_FOE = 'flashbangHitFoe',
	FLASHBANG_HIT_FOE_PER_FLASHBANG = 'flashbangHitFoePerFlashbang',
	FLASHBANG_HIT_FRIEND_PER_FLASHBANG = 'flashbangHitFriendPerFlashbang',
	FLASHBANG_LEADING_TO_KILL = 'flashbangLeadingToKill',
	FLASHBANG_SCORE = 'flashbangScore',
	FLASHBANG_THROWN = 'flashbangThrown',
	HEADSHOT_KILL_PERCENTAGE = 'headshotKillPercentage',
	HE_FOES_DAMAGE_AVG = 'heFoesDamageAvg',
	HE_FOES_DAMAGE = 'heFoesDamage',
	HE_FRIENDS_DAMAGE_AVG = 'heFriendsDamageAvg',
	HE_THROWN = 'heThrown',
	HLTV_RATING = 'hltvRating',
	KD_RATIO = 'kdRatio',
	KILL_DIFFERENCE = 'killDiff',
	KILL_DIFF = 'killDiff',
	KILLS_THROUGH_SMOKE_PER_GAME = 'killsThroughSmokePerGame',
	KNIFE_KILLS_PER_GAME = 'knifeKillsPerGame',
	LEETIFY_RATING = 'leetifyRating',
	LEETIFY_RATING_NORMALIZED = 'leetifyRatingNormalized',
	MOLOTOV_FOES_DAMAGE = 'molotovFoesDamage',
	MOLOTOV_THROWN = 'molotovThrown',
	MULTIKILL_2_ROUNDS = 'multikill2Rounds',
	MULTIKILL_3_ROUNDS = 'multikill3Rounds',
	MULTIKILL_4_ROUNDS = 'multikill4Rounds',
	MULTIKILL_5_ROUNDS = 'multikill5Rounds',
	OPENING_DUEL_ATTEMPTS_PERCENTAGE_BENCHMARK_CT = 'openingDuelAttemptsPercentageBenchmarkCt',
	OPENING_DUEL_ATTEMPTS_PERCENTAGE_BENCHMARK_T = 'openingDuelAttemptsPercentageBenchmarkT',
	OPENING_DUEL_BEST_WEAPON = 'openingDuelBestWeapon',
	OPENING_DUEL_MOST_DIED_TO_WEAPON = 'openingDuelMostDiedToWeapon',
	OPENING_DUEL_MOST_KILLED_PLAYER = 'openingDuelMostKilledPlayer',
	OPENING_DUEL_SUCCESS_PERCENTAGE_BENCHMARK_CT = 'openingDuelSuccessPercentageBenchmarkCt',
	OPENING_DUEL_SUCCESS_PERCENTAGE_BENCHMARK_T = 'openingDuelSuccessPercentageBenchmarkT',
	OPENING_DUEL_TRADE_PERCENTAGE_BENCHMARK_CT = 'openingDuelTradePercentageBenchmarkCt',
	OPENING_DUEL_TRADE_PERCENTAGE_BENCHMARK_T = 'openingDuelTradePercentageBenchmarkT',
	OPENING_RATING = 'openingRating',
	PERSONAL_PERFORMANCE_RATING = 'personalPerformanceRating',
	PLAYER_CLUTCH_1V1_KILLS_PERCENTAGE = 'playerClutch1v1KillsPercentage',
	PLAYER_CLUTCH_1V1_SAVE_PERCENTAGE = 'playerClutch1v1SavePercentage',
	PLAYER_CLUTCH_1V1_WIN_PERCENTAGE = 'playerClutch1v1WinPercentage',
	PLAYER_CLUTCH_1V2_KILLS_PERCENTAGE = 'playerClutch1v2KillsPercentage',
	PLAYER_CLUTCH_1V2_SAVE_PERCENTAGE = 'playerClutch1v2SavePercentage',
	PLAYER_CLUTCH_1V2_WIN_PERCENTAGE = 'playerClutch1v2WinPercentage',
	PLAYER_CLUTCH_1V3_KILLS_PERCENTAGE = 'playerClutch1v3KillsPercentage',
	PLAYER_CLUTCH_1V3_SAVE_PERCENTAGE = 'playerClutch1v3SavePercentage',
	PLAYER_CLUTCH_1V3_WIN_PERCENTAGE = 'playerClutch1v3WinPercentage',
	PLAYER_CLUTCH_1V4_KILLS_PERCENTAGE = 'playerClutch1v4KillsPercentage',
	PLAYER_CLUTCH_1V4_SAVE_PERCENTAGE = 'playerClutch1v4SavePercentage',
	PLAYER_CLUTCH_1V4_WIN_PERCENTAGE = 'playerClutch1v4WinPercentage',
	PLAYER_CLUTCH_1V5_KILLS_PERCENTAGE = 'playerClutch1v5KillsPercentage',
	PLAYER_CLUTCH_1V5_SAVE_PERCENTAGE = 'playerClutch1v5SavePercentage',
	PLAYER_CLUTCH_1V5_WIN_PERCENTAGE = 'playerClutch1v5WinPercentage',
	PLAYER_CLUTCH_KILLS_PERCENTAGE = 'playerClutchKillsPercentage',
	PLAYER_CLUTCH_WIN_PERCENTAGE = 'playerClutchWinPercentage',
	POSITIONING_RATING = 'positioningRating',
	PREAIM = 'preaim',
	REACTION_TIME = 'reactionTime',
	ROUNDS_SURVIVED_PERCENTAGE = 'roundsSurvivedPercentage',
	ROUNDS_SURVIVED = 'roundsSurvived',
	SHOTS_FIRED = 'shotsFired',
	SMOKE_THROWN = 'smokeThrown',
	SPRAY_ACCURACY_AK47 = 'sprayAccuracyAk47',
	SPRAY_ACCURACY_AUG = 'sprayAccuracyAug',
	SPRAY_ACCURACY_M4A1S = 'sprayAccuracyM4A1S',
	SPRAY_ACCURACY_M4A4 = 'sprayAccuracyM4A4',
	SPRAY_ACCURACY_SG553 = 'sprayAccuracySg553',
	SPRAY_ACCURACY = 'sprayAccuracy',
	TASER_KILLS_PER_GAME = 'taserKillsPerGame',
	T_BOMBSITE_AFTERPLANT_RATE = 'tBombsiteAfterplantRate',
	T_BOMBSITE_TAKE_RATE = 'tBombsiteTakeRate',
	TEAM_CLUTCH_ATTEMPTS_PERCENTAGE = 'teamClutchAttemptsPercentage',
	TEAM_CLUTCH_KILLS_PERCENTAGE = 'teamClutchKillsPercentage',
	TIME_TO_DAMAGE = 'reactionTime',
	T_KD_RATIO = 'tKdRatio',
	T_LEETIFY_RATING = 'tLeetifyRating',
	T_OPENING_AGGRESSION_SUCCESS_RATE = 'tOpeningAggressionSuccessRate',
	T_OPENING_DUEL_ATTEMPTS_PERCENTAGE = 'tOpeningDuelAttemptsPercentage',
	T_OPENING_DUEL_SUCCESS_PERCENTAGE = 'tOpeningDuelSuccessPercentage',
	T_OPENING_DUEL_TRADE_PERCENTAGE = 'tOpeningDuelTradePercentage',
	T_OPENING_RATING = 'tOpeningRating',
	TOTAL_ASSISTS = 'totalAssists',
	TOTAL_DAMAGE = 'totalDamage',
	TOTAL_DEATHS = 'totalDeaths',
	TOTAL_GRENADES_THROWN_PER_ROUND = 'totalGrenadesThrownPerRound',
	TOTAL_KILLS = 'totalKills',
	T_POSITIONING_RATING = 'tPositioningRating',
	TRADED_DEATH_ATTEMPTS_PERCENTAGE = 'tradedDeathAttemptsPercentage',
	TRADED_DEATH_ATTEMPTS = 'tradedDeathAttempts',
	TRADED_DEATH_RATE = 'tradedDeathRate',
	TRADED_DEATHS_OPPORTUNITIES_PER_ROUND = 'tradedDeathsOpportunitiesPerRound',
	TRADED_DEATHS_OPPORTUNITIES = 'tradedDeathsOpportunities',
	TRADED_DEATHS_SUCCESS_PERCENTAGE = 'tradedDeathsSuccessPercentage',
	TRADED_DEATHS_SUCCESS = 'tradedDeathsSuccess',
	TRADE_KILL_ATTEMPTS_PERCENTAGE = 'tradeKillAttemptsPercentage',
	TRADE_KILL_ATTEMPTS = 'tradeKillAttempts',
	TRADE_KILL_OPPORTUNITIES_PER_ROUND = 'tradeKillOpportunitiesPerRound',
	TRADE_KILL_OPPORTUNITIES = 'tradeKillOpportunities',
	TRADE_KILLS_SUCCESS_PERCENTAGE = 'tradeKillsSuccessPercentage',
	TRADE_KILLS_SUCCESS = 'tradeKillsSuccess',
	T_ROUND_WIN_RATE_ANCIENT = 'tRoundWinRateAncient',
	T_ROUND_WIN_RATE_DUST2 = 'tRoundWinRateDust2',
	T_ROUND_WIN_RATE_INFERNO = 'tRoundWinRateInferno',
	T_ROUND_WIN_RATE_MIRAGE = 'tRoundWinrateMirage',
	T_ROUND_WIN_RATE_NUKE = 'tRoundWinRateNuke',
	T_ROUND_WIN_RATE_OVERPASS = 'tRoundWinRateOverpass',
	T_ROUND_WIN_RATE = 'tRoundWinRate',
	T_ROUND_WIN_RATE_VERTIGO = 'tRoundWinRateVertigo',
	T_TRADED_DEATH_ATTEMPTS_PERCENTAGE = 'tTradedDeathAttemptsPercentage',
	T_TRADED_DEATHS_OPPORTUNITIES_PER_ROUND = 'tTradedDeathsOpportunitiesPerRound',
	T_TRADED_DEATHS_SUCCESS_PERCENTAGE = 'tTradedDeathsSuccessPercentage',
	T_TRADE_KILL_ATTEMPTS_PERCENTAGE = 'tTradeKillAttemptsPercentage',
	T_TRADE_KILL_OPPORTUNITIES_PER_ROUND = 'tTradeKillOpportunitiesPerRound',
	T_TRADE_KILLS_SUCCESS_PERCENTAGE = 'tTradeKillsSuccessPercentage',
	UNTRADED_KILLS_RATE = 'untradedKillsRate',
	UTILITY_DAMAGE = 'utilityDamage',
	UTILITY_ON_DEATH_AVG = 'utilityOnDeathAvg',
	UTILITY_RATING = 'utilityRating',
	WALLBANG_KILLS_PER_GAME = 'wallbangKillsPerGame',
	WIN_RATE_WITHOUT_PLAYER = 'winRateWithoutPlayer',
	WIN_RATE_WITH_PLAYER = 'winRateWithPlayer',
}

export abstract class Skill {
	// TODO maybe clean these up a bit (i.e. remove unused properties)
	public abstract readonly benchmarkKeyAvg: keyof Benchmark;
	public abstract readonly benchmarkKeyStd: keyof Benchmark;
	public abstract readonly dependsOn: string;
	public abstract readonly description: string;
	public abstract readonly detailsInfo: boolean;
	public abstract readonly displayInGoodBadSummary: boolean;
	public abstract readonly groupId: SkillGroupId;
	public abstract readonly groupTitle: string;
	public abstract readonly id: SkillId;
	public abstract readonly improveTip: boolean;
	public abstract readonly inversed: boolean;
	public abstract readonly isRatio: boolean;
	public abstract readonly smallDescription: string;
	public abstract readonly title: string;

	public readonly assumeNotApplicableIfZero: boolean = false;
	public readonly calculationChangeDates?: CalculationChangeDates;
	public readonly decimalPlaces: number = null;
	public readonly descriptionExtra?: string;
	public readonly fallbackValue: number | string = 0;
	public readonly groupIcon?: string;
	public readonly icon?: string;
	public readonly ignoreZeroAndNullishInRatings: boolean = false; // if `true`, for Aim/Utility/Positioning Rating, ignore this stat if it's 0 or null-ish
	public readonly isNaForLeavers: boolean = false;
	public readonly isScaledForMatch: boolean = false; // all real benchmarks are saved as per-round and have to be rescaled to per-match, the values in fakeBenchmark (including Leetify Rating) are handled differently
	public readonly keepValuesVerbatim?: boolean;
	public readonly leaverReasoning?: string;
	public readonly maxValue?: number;
	public readonly minValue?: number;
	public readonly postSign?: PostSign;
	public readonly shortTitle?: string;
	public readonly shortTitleWithSide?: string;
	public readonly showPositiveSign?: boolean;
	public readonly side?: TeamName;
	public readonly sortable: boolean = true;
	public readonly titleWithSide?: string;

	public getZScore(value: number, benchmark: Benchmark, matchesCount: number): number {
		return ZScoreHelper.getZScore(
			value,
			benchmark[this.benchmarkKeyAvg] as number,
			benchmark[this.benchmarkKeyStd] as number,
			matchesCount,
			this.inversed,
		);
	}

	public getZScoreDeviationStep(value: number, benchmark: Benchmark, matchesCount: number): ZScoreDeviationStep {
		return ZScoreHelper.getZScoreDeviationStep(
			value,
			benchmark[this.benchmarkKeyAvg] as number,
			benchmark[this.benchmarkKeyStd] as number,
			matchesCount,
			this.inversed,
		);
	}

	public getZScoreLabel(value: number, benchmark: Benchmark, matchesCount: number): string {
		return ZScoreHelper.getZScoreLabel(
			this.getZScoreDeviationStep(value, benchmark, matchesCount),
		);
	}

	public getZScoreTextColorClass(value: number, benchmark: Benchmark, matchesCount: number): string {
		return ZScoreHelper.getZScoreTextColorClass(
			this.getZScoreDeviationStep(value, benchmark, matchesCount),
		);
	}

	public formatValue(value: number): string {
		if (value === undefined || value === null) value = this.fallbackValue as number;
		if (typeof value === 'string') return value;

		const rounded = this.getRoundedValue(value);

		switch (this.postSign) {
			case '+': return value > 0 ? `+${rounded}` : `${rounded}`;
			default: return `${rounded}${this.postSign || ''}`;
		}
	}

	public getRoundedValue(value: number): string {
		const decimalPlaces = this.getDecimalPlaces();
		return value.toFixed(decimalPlaces);
	}

	protected getDecimalPlaces(): number {
		if (this.decimalPlaces !== null) return this.decimalPlaces;

		switch (this.postSign) {
			case '°':
			case '+':
				return 2;

			case 'sec':
				return 1;

			case '%':
			case '$':
			case 'ms':
			default:
				return 0;
		}
	}
}
