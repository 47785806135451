import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NotificationResponse } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {

	private defaultTitle = 'Leetify';
	private readonly notificationSource = new Subject<NotificationResponse>();
	public readonly notifications$ = this.notificationSource.asObservable();

	public constructor(
		protected readonly http: HttpClient,
		protected readonly titleService: Title,
	) {
		//
	}

	public reloadUserNotifications(): void {
		this.http.get<NotificationResponse>(`${environment.homeApiUrl}/api/posts/v1/notifications`).subscribe(
			(notificationResponse: NotificationResponse) => {
				this.notificationSource.next(notificationResponse);
				return notificationResponse;
			},
			(e) => {
				console.log(e);
				return null;
			},
		);
	}

	public async markNotificationsAsRead() {
		try {
			await this.http.post(`${environment.homeApiUrl}/api/posts/v1/notifications/read`, {}).toPromise();
		} catch (err) {
			console.error(err);
		}
	}

	public setFaviconAndTitle(notificationCount: number) {
		// set favicon
		const faviconNumber = notificationCount > 9 ? 10 : notificationCount;
		const faviconPath = `assets/icons/favicons/${faviconNumber}.svg`;

		const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");

		if (link) link.href = faviconPath;

		// set title
		const newTitle = notificationCount > 9 ? `(9+) ${this.defaultTitle}` : `(${notificationCount}) ${this.defaultTitle}`;

		const pathname = typeof window !== 'undefined' && window.location.pathname;

		// only on homepage
		if (pathname === '/home/' || pathname === '/home') {
			this.titleService.setTitle(notificationCount === 0 ? this.defaultTitle : newTitle);
		}
	}
}
