const frontendRootUrl = 'https://ezmid.leetify.com';

export const environment = {
	production: false,

	// locale
	angularDateFormat: 'YYYY-MM-dd',
	momentDateFormat: 'YYYY-MM-DD',
	dateFormat: 'YYYY-MM-DD',

	apiUrl: 'https://api.lol-staging.leetify.com',

	// api urls
	csApiUrl: 'https://api.cs-staging.leetify.com',
	homeApiUrl: 'https://home-api.i-staging.leetify.com',
	lolApiUrl: 'https://api.lol-staging.leetify.com',
	userApiUrl: 'https://id.i-staging.leetify.com',
	trackingApiBaseUrl: 'https://tracking-api.i-staging.leetify.com',

	// frontend urls
	frontendRootUrl,
	csFrontendUrl: `${frontendRootUrl}/app`,
	homeFrontendUrl: `${frontendRootUrl}/home`,
	lolFrontendUrl: `${frontendRootUrl}/lol`,

	// frontend-adjacent service urls
	staticAssetsUrl: 'https://static-assets.i-staging.leetify.com',
};
