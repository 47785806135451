import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TrackerService } from './services/tracker.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { NavigationEnd, RouterEvent } from '@angular/router';
// import { environment } from 'src/environments/environment';
// import { GoogleAnalyticsHelper } from './helpers/ga.helper';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	protected readonly isBrowser: boolean;
	private readonly destroy$ = new Subject<void>();
	private lastUrl: string | null = null;
	private pageEnterTime: number = Date.now();
	router: any;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private trackerService: TrackerService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		if (this.isBrowser) {
			// if (environment.production) {
			// 	GoogleAnalyticsHelper.init();
			// }
		}
	}

	public ngOnInit(): void {
		if (!this.isBrowser) {
			return;
		}

		this.pageEnterTime = Date.now();

		this.router.events
			.pipe(
				filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd),
				takeUntil(this.destroy$),
			)
			.subscribe((navigationEvent: NavigationEnd) => {
				if (this.lastUrl) {
					const now: number = Date.now();
					const timeOnPageMs: number = now - this.pageEnterTime;
					const timeOnPageSeconds: number = Math.floor(timeOnPageMs / 1000);

					const pageLeaveData: {
						currentUrl: string;
						previousUrl: string;
						timeOnPageMs: number;
						timeOnPageSeconds: number;
					} = {
						currentUrl: navigationEvent.urlAfterRedirects,
						previousUrl: this.lastUrl || '',
						timeOnPageMs,
						timeOnPageSeconds,
					};

					this.trackerService.track('$pageleave', { leaveData: pageLeaveData });
				}

				const previousUrl: string = this.lastUrl || '';
				this.lastUrl = navigationEvent.urlAfterRedirects;
				this.pageEnterTime = Date.now();

				const pageViewData: {
					currentUrl: string;
					previousUrl: string;
				} = {
					currentUrl: navigationEvent.urlAfterRedirects,
					previousUrl,
				};

				this.trackerService.track('$pageview', { viewData: pageViewData });
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		if (this.lastUrl) {
			const now = Date.now();
			const timeOnPageMs = now - this.pageEnterTime;
			const timeOnPageSeconds = Math.floor(timeOnPageMs / 1000);
			const pageLeaveData: {
				currentUrl: string;
				previousUrl: string;
				timeOnPageMs: number;
				timeOnPageSeconds: number;
			} = {
				currentUrl: '',
				previousUrl: this.lastUrl,
				timeOnPageMs,
				timeOnPageSeconds,
			};

			this.trackerService.track('$pageleave', { leaveData: pageLeaveData });
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	public handleBeforeUnload(event: Event): void {
		if (this.lastUrl) {
			const now = Date.now();
			const timeOnPageMs = now - this.pageEnterTime;
			const timeOnPageSeconds = Math.floor(timeOnPageMs / 1000);
			const pageLeaveData: {
				currentUrl: string;
				previousUrl: string;
				timeOnPageMs: number;
				timeOnPageSeconds: number;
			} = {
				currentUrl: '',
				previousUrl: this.lastUrl,
				timeOnPageMs,
				timeOnPageSeconds,
			};

			this.trackerService.track('$pageleave', { leaveData: pageLeaveData });
		}
	}
}
