<div #fallbackHoverTarget class="avatar-border --{{ styleOverride || style }}">
	<div class="border-inner">
		<div class="background">
			<img
				alt=""
				class="avatar"
				(error)="onProfilePictureErrored($event)"
				[src]="avatarUrl"
			>
		</div>
	</div>
</div>

@if (showCosmetics) {
	<leetify-cosmetic-image
		[cosmeticId]="borderOverride || borderId"
		[hoverTarget]="hoverTarget || fallbackHoverTarget"
		[imageType]="CosmeticImageType.AVATAR_BORDER"
		[isAnimated]="isBorderAnimated"
		[isOnlyAnimatedOnHover]="isBorderOnlyAnimatedOnHover"
	></leetify-cosmetic-image>
}
