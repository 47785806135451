export enum ClubLeaderboardBadgeType {
	AIM_RATING = 'aim_rating',
	ENEMIES_FLASHED = 'enemies_flashed',
	LEAP_FROG = 'leap_frog',
	LEETIFY_RATING = 'leetify_rating',
	PREMIER_RATING = 'premier_rating',
	TOTAL_KILLS = 'total_kills',
	TOTAL_MATCHES = 'total_matches',
	TRADE_KILLS = 'trade_kills',
	UTILITY_RATING = 'utility_rating',
}

export enum ClubLeaderboardType {
	AIM_RATING = 'aim-rating',
	ENEMIES_FLASHED = 'enemies-flashed',
	LEETIFY_RATING = 'leetify-rating',
	PREMIER_RATING = 'premier-rating',
	TOTAL_KILLS = 'total-kills',
	TOTAL_MATCHES = 'total-matches',
	TRADE_KILLS = 'trade-kills',
	UTILITY_RATING = 'utility-rating',
}

export enum ClubMemberComparisonView {
	BOMBSITES = 'bombsites',
	FOUR_VS_FIVE_SITUATIONS = 'four-vs-five-situations',
	IMPACT = 'impact',
	OPENING_DUELS = 'opening-duels',
	TRADED_DEATHS = 'traded-deaths',
	TRADE_KILLS = 'trade-kills',
}

export enum ClubMemberRole {
	OWNER = 'owner',
	ADMIN = 'admin',
	MEMBER = 'member',
	INVITED_MEMBER = 'invited_member',
	MEMBERSHIP_REQUESTED = 'membership_requested',
}
