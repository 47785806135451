import { HighlightDTO, ProgressReport2024IdentityStatSkillId } from '../dto';
import { DataSource, MatchResult, MatchmakingRankType, Recap2023PersonalBestSkillId, RoundEconomyType } from '../enums';
import { MatchScores } from '../types';

export interface Recap2024DTO {
	activity: Recap2024ActivityDTO;
	clutches: Recap2024ClutchesDTO;
	flashes: Recap2024FlashesDTO;
	friends: Recap2024FriendsDTO;
	friendsRecaps: Recap2024FriendsRecapsDTO;
	identity: Recap2024IdentityDTO;
	kills: Recap2024KillsDTO;
	maps: Recap2024MapsDTO;
	meta: Recap2024MetaDTO;
	multikills: Recap2024MultikillsDTO;
	openingDuels: Recap2024OpeningDuelsDTO;
	personalBests: Recap2024PersonalBestsDTO;
	players: Recap2024PlayersDTO;
	rankProgression: Recap2024RankProgressionDTO;
	rounds: Recap2024RoundsDTO;
	topHighlights: Recap2024TopHighlightsDTO;
	utility: Recap2024UtilityDTO;
	weapons: Recap2024WeaponsDTO;
}

export interface Recap2024ActivityDTO {
	hoursPlayed: number;
	hoursPlayedPercentile: number;
	matchesPlayedByMonth: [number, number, number, number, number, number, number, number, number, number, number, number];
	matchesPlayed: number;
}

export interface Recap2024ClutchesDTO {
	oneV1ClutchesLost: number;
	oneV1ClutchesWon: number;
	oneV1WinRatePercentile: number;
	oneV2ClutchesLost: number;
	oneV2ClutchesWon: number;
	oneV2WinRatePercentile: number;
	oneV3ClutchesLost: number;
	oneV3ClutchesWon: number;
	oneV3WinRatePercentile: number;
	oneV4ClutchesLost: number;
	oneV4ClutchesWon: number;
	oneV4WinRatePercentile: number;
	oneV5ClutchesLost: number;
	oneV5ClutchesWon: number;
	oneV5WinRatePercentile: number;
	overallWinRatePercentile: number;
}

export interface Recap2024FlashesDTO {
	totalBlindedSec: number;
	totalBlindedSecPercentile: number;
	totalEnemiesFlashedSec: number;
	totalEnemiesFlashedSecPercentile: number;
}

export interface Recap2024FriendsDTO {
	friends: Recap2024FriendsFriendDTO[];
}

export interface Recap2024FriendsFriendDTO {
	aimRating: number;
	deaths: number;
	kills: number;
	leetifyRating: number;
	matchesPlayed: number;
	matchesWon: number;
	roundsPlayed: number;
	steam64Id: string;
	steamAvatarUrl: string;
	steamNickname: string;
	teamFlashes: number;
	teamKills: number;
	totalTeamFlashDurationSec: number;
	utilityRating: number;
}

export interface Recap2024FriendsRecapsDTO {
	friends: Recap2024FriendsRecapsFriendDTO[];
}

export interface Recap2024FriendsRecapsFriendDTO {
	steam64Id: string;
	steamAvatarUrl: string;
	steamNickname: string;
}

export interface Recap2024IdentityDTO {
	stats: Recap2024IdentityStatDTO[];
}

export interface Recap2024IdentityStatDTO {
	percentile?: number;
	score: number;
	skillId: ProgressReport2024IdentityStatSkillId;
	value: number;
}

export interface Recap2024KillsDTO {
	airborneKills: number;
	airborneKillsPercentile: number;
	killsThroughSmoke: number;
	killsThroughSmokePercentile: number;
	killsWhileBlind: number;
	killsWhileBlindPercentile: number;
	knifeKills: number;
	knifeKillsPercentile: number;
	taserKills: number;
	taserKillsPercentile: number;
	wallbangKills: number;
	wallbangKillsPercentile: number;
}

export interface Recap2024MapsDTO {
	mostPlayedMaps: Recap2024MapsMostPlayedMapDTO[];
	uniqueMapsPlayed: number;
}

export interface Recap2024MapsMostPlayedMapDTO {
	leetifyRating: number;
	leetifyRatingPercentile: number;
	mapName: string;
	matchesPlayed: number;
	matchesPlayedPercentile: number;
	matchesWon: number;
}

export interface Recap2024MetaDTO {
	steam64Id: string;
	steamAvatarUrl: string;
	steamNickname: string;
}

export interface Recap2024MultikillsDTO {
	aceRounds: number;
	aceRoundsPercentile: number;
	doubleKillRounds: number;
	doubleKillRoundsPercentile: number;
	quadrupleKillRounds: number;
	quadrupleKillRoundsPercentile: number;
	tripleKillRounds: number;
	tripleKillRoundsPercentile: number;
}

export interface Recap2024OpeningDuelsDTO {
	lossRatePercentile: number;
	openingDuelsLostTraded: number;
	openingDuelsLostUntraded: number;
	openingDuelsWon: number;
	winRatePercentile: number;
}

export type Recap2024PersonalBestsDTO = {
	[key in Recap2023PersonalBestSkillId]?: Recap2024PersonalBestDTO;
};

export interface Recap2024PersonalBestDTO {
	gameFinishedAt: string;
	gameId: string;
	leetifyAvgPercentile?: number;
	mapName: string;
	matchResult: MatchResult;
	scores: MatchScores;
	value: number;
}

export interface Recap2024PlayersDTO {
	bannedPlayersGameIds: string[];
	bannedPlayers: number;
	bannedPlayersPercentile: number;
	leetifyStaffGameIds: string[];
	leetifyStaff: number;
	leetifyUsersGameIds: string[];
	leetifyUsers: number;
	leetifyUsersPercentile: number;
	proPlayersGameIds: string[];
	proPlayers: number;
	uniquePlayers: number;
	uniquePlayersPercentile: number;
}

export interface Recap2024RankProgressionDTO {
	ranks: Recap2024RankProgressionRankDTO[];
}

export interface Recap2024RankProgressionRankDTO {
	dataSource: DataSource;
	mapName: string | null;
	matchesPlayed: number;
	maxSkillLevel: number;
	rankType: MatchmakingRankType | null;
}

export interface Recap2024RoundsDTO {
	roundsByEconomyType: {
		[roundEconomyType in RoundEconomyType]?: {
			roundsPlayed: number;
			roundsWon: number;
			winRatePercentile: number;
		};
	};
}

export interface Recap2024TopHighlightsDTO {
	highlights: HighlightDTO[];
}

export interface Recap2024UtilityDTO {
	flashbangThrown: number;
	heThrown: number;
	molotovThrown: number;
	mostUnusedUtilityOnDeathPercentile: number;
	smokeThrown: number;
	totalUnusedUtilityOnDeath: number;
}

export interface Recap2024WeaponsDTO {
	enemyKillsByWeaponGroup: { [group in Recap2024WeaponsWeaponGroup]?: number };
	grenadeKillsPercentagePercentile: number;
	heavyKillsPercentagePercentile: number;
	pistolKillsPercentagePercentile: number;
	rifleKillsPercentagePercentile: number;
	smgKillsPercentagePercentile: number;
	sniperKillsPercentagePercentile: number;
	teamKillsPercentile: number;
	totalEnemyKills: number;
	totalTeamKills: number;
}

export enum Recap2024WeaponsWeaponGroup {
	GRENADES = 'grenades',
	HEAVY = 'heavy',
	PISTOL = 'pistol',
	RIFLE = 'rifle',
	SMG = 'smg',
	SNIPER = 'sniper',
}
