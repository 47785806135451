<div class="title-mobile">Search for a Leetify user</div>

<div #inputContainer class="search-box" [ngClass]="{ 'results-open': isSearchOpen }">
	<leetify-icon [icon]="Icon.SEARCH" style="font-size: 1.25rem"></leetify-icon>
	<input
		#input
		type="text"
		class="form-control"
		placeholder="Player search - Steam/Riot/FACEIT: Enter nickname, ID, or profile link"
		(focus)="searchFocused()"
		[(ngModel)]="searchTerm"
	/>

	<span class="clear-search-button" (click)="clearSearch()" *ngIf="searchTerm">
		<leetify-icon [icon]="Icon.X_CROSS_CLOSE"></leetify-icon>
	</span>
</div>

<div #resultsContainer class="search-results-container" [ngClass]="{ 'results-open': isSearchOpen }">
	<div *ngIf="user" class="search-results">

		@if (!searchTerm) {
			<div class="heading">
				Recent
			</div>

			<div *ngIf="recentSearchResults.length < 1" class="result-row --non-interactible">
				No Recent Searches
			</div>

			<div *ngFor="let result of recentSearchResults" class="result-row">
				<div class="text-truncate" (click)="goToProfile(result)">
					<img class="rounded-circle" [src]="result.pictureUrl || 'assets/images/anon.png'" alt="picture"/>
					{{ result.nickname }}
				</div>

				<div class="user-actions">
					<button class="action btn icon-action-button" (click)="spectate(result)" [disabled]="result.spectateDisabled" [ngbTooltip]="result.spectateTooltip" openDelay="300" tooltipClass="default-tooltip" container="body">
						@if (result.spectateDisabled) {
							<leetify-icon [icon]="Icon.EYE_OFF" class="icon-size"></leetify-icon>
						} @else {
							<leetify-icon [icon]="Icon.EYE_OPEN" class="icon-size"></leetify-icon>
						}
					</button>

					<a href="{{ csFrontendUrl }}/compare/{{ user.steam64Id }}/{{ result.steam64Id }}" class="action btn icon-action-button" ngbTooltip="Compare" openDelay="300" tooltipClass="default-tooltip" container="body" (click)="saveRecentResult(result)">
						<i class="fa fa-exchange-alt" style="font-size: 1.063rem"></i>
					</a>

					<leetify-common-follow-button [userId]="result.userId" [userSearch]="true"></leetify-common-follow-button>

					<a href="{{ csFrontendUrl }}/profile/{{ result.steam64Id }}" class="action btn btn-primary" (click)="saveRecentResult(result)">
						Profile
					</a>

					<span class="remove-friend-button" title="Remove from Recent" aria-label="Remove from Recent" (click)="removeRecentResult(result)">
					<leetify-icon [icon]="Icon.X_CROSS_CLOSE"></leetify-icon>
				</span>
				</div>
			</div>

			<div class="container-suggested">
					@if (suggestedResults && isMobile){
						<div class="heading">
							Suggested for you
						</div>

						<div *ngFor="let user of suggestedResults">
							<div *ngIf="user.nickname" class="result-row">
								<div class="text-truncate">
									<img class="rounded-circle" [src]="user.pictureUrl || 'assets/images/anon.png'" alt="picture"/>

									{{ user.nickname }}
								</div>
								<div class="user-actions">
									<leetify-common-follow-button *ngIf="user.userId" [userId]="user.userId" [outline]="false"></leetify-common-follow-button>
								</div>
							</div>
						</div>
					}
			</div>
		}

		<ng-container *ngIf="searchTerm">
			<div class="heading">
				Search Results
				<img src="assets/images/spinner.svg" class="spinner" *ngIf="requestInProgress" alt="spinner"/>
			</div>

			<div *ngIf="!requestInProgress && searchResults.length < 1" class="result-row --non-interactible">
				No Search Results
			</div>

			@for (result of searchResults; track $index) {
				<div class="result-row">
					<div class="meta-wrapper" (click)="goToProfile(result)">
						<img class="rounded-circle" [src]="result.pictureUrl || 'assets/images/anon.png'" />
						<div class="names-wrapper">
							<div class="nickname-wrapper">
								{{ result.nickname }}
								<div
									*ngIf="result.verifiedPro"
									ngbTooltip="This is the real {{ result.nickname }}"
									tooltipClass="default-tooltip --dark"
									class="badge badge-dark ml-1 px-1"
									style="vertical-align:1px"
								>
									<i class="mdi mdi-check-bold mr-1"></i>
									Pro Player
								</div>
							</div>

							<div class="game-name-wrapper">
								@if (result.steamUsername) {
									<div class="game-name ellipsis">
										<leetify-icon [icon]="Icon.CS2_LOGO_TWO_FILLED" class="game-icon"></leetify-icon>
										{{ result.steamUsername }}
									</div>
								}

								@if (result.lolUsername) {
									<div class="game-name ellipsis">
										<leetify-icon [icon]="Icon.LOL_LOGO_FILLED" class="game-icon"></leetify-icon>
										{{ result.lolUsername }}
									</div>
								}
							</div>
						</div>
					</div>

					<div class="user-actions">
						@if (result.showSpectate) {
							<button
								class="action btn icon-action-button"
								(click)="spectate(result)" [disabled]="result.spectateDisabled" [ngbTooltip]="result.spectateTooltip" openDelay="300" tooltipClass="default-tooltip" container="body" (click)="saveRecentResult(result)">
								@if (result.spectateDisabled) {
									<leetify-icon [icon]="Icon.EYE_OFF" class="icon-size"></leetify-icon>
								} @else {
									<leetify-icon [icon]="Icon.EYE_OPEN" class="icon-size"></leetify-icon>
								}
							</button>
						}

						@if (result.showCompare) {
							<a href="{{ csFrontendUrl }}/compare/{{ user.steam64Id }}/{{ result.steam64Id }}" class="action btn icon-action-button" ngbTooltip="Compare" openDelay="300" tooltipClass="default-tooltip" container="body" (click)="saveRecentResult(result)">
								<i class="fa fa-exchange-alt" style="font-size: 1.063rem"></i>
							</a>
						}

						<leetify-common-follow-button [userId]="result.userId" [userSearch]="true"></leetify-common-follow-button>

						@if (result.showProfile) {
							<a href="{{ csFrontendUrl }}/profile/{{ result.steam64Id }}" class="action btn btn-primary" (click)="saveRecentResult(result)">
								Profile
							</a>
						}
					</div>
				</div>
			}

		</ng-container>
	</div>

	<div *ngIf="!user" class="authenticate-cta">
		Please
		<a href="{{ homeFrontendUrl }}/auth/login" class="cursor-pointer text">log in</a>
		or
		<a href="{{ homeFrontendUrl }}/auth/signup" class="cursor-pointer text">sign up</a>
		to search for players.
	</div>
</div>
