import { Component, ElementRef, HostBinding, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { CosmeticId, CosmeticImageType, CosmeticImageUrl } from 'leetify-cosmetics-dtos/enums';
import { emptySvgDataUrl } from 'leetify-cosmetics-dtos/constants';
import { CosmeticsHelper } from 'src/components/helpers/cosmetics.helper';
import { CosmeticsService } from 'src/components/services/cosmetics.service';
import { ViewHelper } from 'src/components/helpers/view.helper';

interface ImageVariantDefinition {
	imageUrl: CosmeticImageUrl;
	mimeType: string;
}

interface ImageVariant {
	key: string;
	mimeType: string;
	src: string;
}

@Component({
	selector: 'leetify-cosmetic-image',
	templateUrl: './cosmetic-image.component.html',
	styleUrls: ['./cosmetic-image.component.scss'],
})
export class CosmeticImageComponent implements OnChanges, OnDestroy, OnInit {
	/** If set and `isOnlyAnimatedOnHover` is `true`, border will be animated when hovering over `hoverTarget` instead of when hovering over the avatar. */
	@HostBinding('class.--external-hover-target')
	@Input()
	public hoverTarget: HTMLElement;

	/** If `true`, the cosmetic border will be animated. */
	@Input() public isAnimated: boolean = false;

	/** If `true`, and `isAnimated` is also `true`, the image will only be animated when hovering over the avatar. Ignored if `isAnimated` is `false`. */
	@HostBinding('class.--only-animated-on-hover')
	@Input()
	public isOnlyAnimatedOnHover: boolean = false;

	@Input() public cosmeticId: CosmeticId;
	@Input() public imageType: CosmeticImageType;

	protected readonly isBrowser: boolean;
	protected readonly ngUnsubscribe = new Subject<void>();

	/** All possible image variants, preferred variants first. */
	protected imageVariants: ImageVariant[] = [];

	protected isHovering: boolean = false;
	protected previousHoverTarget: HTMLElement;
	protected showAnimations: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		protected readonly cosmeticsService: CosmeticsService,
		protected readonly eref: ElementRef<HTMLElement>,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		this.onHoverTargetMouseenter = this.onHoverTargetMouseenter.bind(this);
		this.onHoverTargetMouseleave = this.onHoverTargetMouseleave.bind(this);
	}

	protected async applyImages(): Promise<void> {
		const cosmeticId = this.cosmeticId || CosmeticId.NONE;

		const animated = this.showAnimations
			&& this.isAnimated
			&& (this.isHovering || !this.isOnlyAnimatedOnHover);

		this.imageVariants = this.getImageVariantDefinitions(animated).map(({ imageUrl, mimeType }, index) => {
			const src = CosmeticsHelper.getImageUrl(cosmeticId, this.imageType, imageUrl);

			return {
				mimeType,
				src,
				key: src === emptySvgDataUrl ? `empty${index}` : src,
			};
		});
	}

	/** @returns image variant definitions, preferred variants first */
	protected getImageVariantDefinitions(animated: boolean): ImageVariantDefinition[] {
		return animated
			? [
				{ imageUrl: CosmeticImageUrl.ANIMATED_LOOPING_HQ_AVIF, mimeType: 'image/avif' },
				{ imageUrl: CosmeticImageUrl.ANIMATED_LOOPING_LOSSLESS_WEBP, mimeType: 'image/webp' },
				{ imageUrl: CosmeticImageUrl.STATIC_LOSSLESS_PNG, mimeType: 'image/png' },
			]
			: [
				{ imageUrl: CosmeticImageUrl.STATIC_HQ_AVIF, mimeType: 'image/avif' },
				{ imageUrl: CosmeticImageUrl.STATIC_HQ_WEBP, mimeType: 'image/webp' },
				{ imageUrl: CosmeticImageUrl.STATIC_LOSSLESS_PNG, mimeType: 'image/png' },
			];
	}

	protected addMouseoverEventListeners(): void {
		if (!this.isBrowser) return;

		// set `isHovering` based on the current hover state of the hover target
		this.isHovering = (this.hoverTarget || this.eref?.nativeElement)?.matches(':hover');

		if (this.hoverTarget === this.previousHoverTarget) return;

		if (this.previousHoverTarget) {
			this.previousHoverTarget.removeEventListener('mouseenter', this.onHoverTargetMouseenter);
			this.previousHoverTarget.removeEventListener('mouseleave', this.onHoverTargetMouseleave);
		}

		this.previousHoverTarget = this.hoverTarget;

		if (this.hoverTarget) {
			this.hoverTarget.addEventListener('mouseenter', this.onHoverTargetMouseenter);
			this.hoverTarget.addEventListener('mouseleave', this.onHoverTargetMouseleave);
		}
	}

	protected async handleAnticipateStaticAnimations(): Promise<void> {
		const preferredStaticImageVariantDefinition = this.getImageVariantDefinitions(false)?.[0];
		if (!preferredStaticImageVariantDefinition) return;

		const cosmeticId = this.cosmeticId || CosmeticId.NONE;

		await CosmeticsHelper.precacheImage(cosmeticId, this.imageType, preferredStaticImageVariantDefinition.imageUrl);
	}

	protected handleShowAnimations(showAnimations: boolean): void {
		this.showAnimations = showAnimations;
		this.applyImages();
	}

	protected onCosmeticImageErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, emptySvgDataUrl);
	}

	protected onHoverTargetMouseenter(): void {
		this.isHovering = true;
		this.applyImages();
	}

	protected onHoverTargetMouseleave(): void {
		this.isHovering = false;
		this.applyImages();
	}

	@HostListener('mouseenter')
	protected onMouseenter(): void {
		if (this.hoverTarget) return; // ignore mouseover events on the avatar component's element if a hover target is set

		this.isHovering = true;
		this.applyImages();
	}

	@HostListener('mouseleave')
	protected onMouseleave(): void {
		if (this.hoverTarget) return; // ignore mouseover events on the avatar component's element if a hover target is set

		this.isHovering = false;
		this.applyImages();
	}

	public ngOnChanges(): void {
		this.addMouseoverEventListeners();
		this.applyImages();
	}

	public ngOnInit(): void {
		this.showAnimations = this.cosmeticsService.showAnimations$.getValue();

		this.cosmeticsService.anticipateStaticAnimations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.handleAnticipateStaticAnimations());
		this.cosmeticsService.showAnimations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showAnimations) => this.handleShowAnimations(showAnimations));

		this.addMouseoverEventListeners();
		this.applyImages();
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
