import { BehaviorSubject, Subject } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserPreferencesService } from 'src/components/services/user-preferences.service';

@Injectable({
	providedIn: 'root',
})
export class CosmeticsService {
	/** When emitted, components that show animated cosmetics should prepare to switch to static variants. Emitted e.g. when the window gets minimzed. */
	public readonly anticipateStaticAnimations$ = new Subject<void>();

	/** Whether or not cosmetics should be animated. If `false`, cosmetics should still be displayed, but use static images. */
	public readonly showAnimations$ = new BehaviorSubject<boolean>(false);

	protected isBrowser: boolean;

	protected blurTimeout: ReturnType<typeof setTimeout> | null = null;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		protected readonly userPreferencesService: UserPreferencesService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		if (this.isBrowser) {
			window.addEventListener('focus', () => this.onWindowFocus());
			window.addEventListener('blur', () => this.onWindowBlur());
		}

		this.userPreferencesService.userPreferences$.subscribe(() => this.evaluateAndEmitShowAnimations());

		this.evaluateAndEmitShowAnimations();
	}

	protected evaluateAndEmitShowAnimations(): void {
		if (!this.isBrowser) return this.showAnimations$.next(false);

		const isAnimationsAllowedByOS = !window.matchMedia('(prefers-reduced-motion)').matches;
		if (!isAnimationsAllowedByOS) return this.showAnimations$.next(false);

		const isAnimationsAllowedByWindowFocus = document.hasFocus();
		if (!isAnimationsAllowedByWindowFocus) return this.showAnimations$.next(false);

		const isAnimationsAllowedByUserPreferences = this.userPreferencesService.userPreferences?.isAnimatedCosmeticsEnabled ?? true;
		if (!isAnimationsAllowedByUserPreferences) return this.showAnimations$.next(false);

		this.showAnimations$.next(true);
	}

	protected onWindowFocus(): void {
		if (this.blurTimeout) clearTimeout(this.blurTimeout);

		this.evaluateAndEmitShowAnimations();
	}

	// when the page goes out of focus, tell components that they should expect a switch to not showing animation soon (so they can e.g. pre-cache assets), but only apply static animations after a few seconds
	protected onWindowBlur(): void {
		this.anticipateStaticAnimations$.next();
		this.blurTimeout = setTimeout(() => this.evaluateAndEmitShowAnimations(), 5000);
	}
}
