import { firstValueFrom, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvatarMetadataDTO } from 'leetify-cosmetics-dtos/dto';
import { AvatarStyle, CosmeticId } from 'leetify-cosmetics-dtos/enums';
import { environment } from 'src/environments/environment';
import { ViewHelper } from 'src/components/helpers/view.helper';

enum IdType {
	LEETIFY_USER_ID = 'user-id',
	STEAM64_ID = 'steam64-id',
}

type AvatarMetadataPromiseCache = {
	[idType in IdType]: {
		[userId: string]: Promise<AvatarMetadataDTO>;
	};
};

@Injectable({
	providedIn: 'root',
})
export class AvatarsService {
	protected readonly avatarMetadataSource = new Subject<AvatarMetadataDTO>();
	public readonly avatarMetadata$ = this.avatarMetadataSource.asObservable();

	protected readonly avatarMetadataPromiseCache: AvatarMetadataPromiseCache = {
		[IdType.LEETIFY_USER_ID]: {},
		[IdType.STEAM64_ID]: {},
	};

	public constructor(
		protected readonly http: HttpClient,
	) {
		//
	}

	public getDefaultMetadata(): AvatarMetadataDTO {
		return {
			avatarUrl: ViewHelper.PROFILE_PICTURE_FALLBACK,
			style: AvatarStyle.DEFAULT,
		};
	}

	public async getAvatarByLeetifyUserId(userId: string, forceRefresh = false): Promise<AvatarMetadataDTO> {
		return this.getAndCacheAvatarMetadata(IdType.LEETIFY_USER_ID, userId, forceRefresh);
	}

	public async getAvatarBySteam64Id(steam64Id: string, forceRefresh = false): Promise<AvatarMetadataDTO> {
		return this.getAndCacheAvatarMetadata(IdType.STEAM64_ID, steam64Id, forceRefresh);
	}

	/** Primarily for use after uploading a new avatar image. */
	public appendAvatarImageUrlRefreshSuffix(userId: string): void {
		const cachedPromise = this.avatarMetadataPromiseCache[IdType.LEETIFY_USER_ID][userId];
		if (!cachedPromise) return;

		// wait for the promise to resolve (if it already was, the callback is called immediately), then override the border and re-emit
		cachedPromise.then((metadata) => {
			const avatarUrl = new URL(metadata.avatarUrl);
			avatarUrl.searchParams.set('_refresh', (+new Date()).toString());

			metadata.avatarUrl = avatarUrl.toString();

			this.avatarMetadataSource.next(metadata);
		});
	}

	/** Primarily for use after changing the selected avatar border. */
	public forceAvatarBorder(userId: string, avatarBorderId: CosmeticId): void {
		const cachedPromise = this.avatarMetadataPromiseCache[IdType.LEETIFY_USER_ID][userId];
		if (!cachedPromise) return;

		// wait for the promise to resolve (if it already was, the callback is called immediately), then override the border and re-emit
		cachedPromise.then((metadata) => {
			metadata.borderId = avatarBorderId;
			this.avatarMetadataSource.next(metadata);
		});
	}

	protected async getAndCacheAvatarMetadata(idType: IdType, id: string, forceRefresh = false): Promise<AvatarMetadataDTO> {
		if (this.avatarMetadataPromiseCache[idType][id] && !forceRefresh) {
			const cachedMetadata = await this.avatarMetadataPromiseCache[idType][id];
			this.avatarMetadataSource.next(cachedMetadata);

			return cachedMetadata;
		}

		const promise = firstValueFrom(
			this.http.get<AvatarMetadataDTO>(this.buildAvatarMetadataRequestUrl(idType, id)),
		).catch((err): AvatarMetadataDTO => {
			console.error(err);
			return this.getDefaultMetadata();
		});

		this.avatarMetadataPromiseCache[idType][id] = promise;

		promise.then((metadata) => {
			this.addToCaches(promise);
			this.avatarMetadataSource.next(metadata);
		});

		return promise;
	}

	protected buildAvatarMetadataRequestUrl(idType: IdType, id: string): string {
		switch (idType) {
			case IdType.LEETIFY_USER_ID: return `${environment.userApiUrl}/avatar-metadata/v1/${id}`;
			case IdType.STEAM64_ID: return `${environment.csApiUrl}/api/avatar-metadata/${id}`;
		}
	}

	protected async addToCaches(promise: Promise<AvatarMetadataDTO>): Promise<void> {
		const metadata = await promise;

		if (metadata.steam64Id) this.avatarMetadataPromiseCache[IdType.STEAM64_ID][metadata.steam64Id] = promise;
		if (metadata.userId) this.avatarMetadataPromiseCache[IdType.LEETIFY_USER_ID][metadata.userId] = promise;
	}
}
