<div class="nav-container" [ngClass]="{
	 'small-nav-open': isOpen && !navigationActive,
	 'big-nav-open': navigationActive }">
	<aside>
		<div class="mobile-nav-toggle">
			<button class="small-mobile-check" aria-label="Toggle Menu" title="Toggle Menu" (click)="toggleNav($event)">
				<leetify-icon [icon]="navigationActive ? Icon.X_CROSS_CLOSE : Icon.MENU"></leetify-icon>
			</button>

			<a routerLink='/' class="logo">
				<img src="assets/images/leetify-logo-primary-white.svg" alt="Leetify">
			</a>
		</div>

		<leetify-common-player-search
			class="small-mobile-check"
			[focusSearchInput$]="focusSearchInput$"
			[ngClass]="{ '--active': searchActive }"
			(close)="toggleSearch(null, false)"
		></leetify-common-player-search>

		@if (user) {
			<div class="right">
				<button class="mobile-search-toggle top-nav" aria-label="Toggle Search" title="Toggle Search" (click)="toggleSearch($event)">
					<leetify-icon [icon]="Icon.SEARCH" [ngClass]="{ '--active': searchActive }"></leetify-icon>
				</button>

				<div class="pro">
					<a
						href="{{ csFrontendUrl }}/pro"
						class="btn"
						[ngClass]="proButtonClass"
						container="body"
						[ngbTooltip]="user.isCollector ? 'Thanks for being a Pioneer!' : null"
						openDelay="600"
						placement="bottom"
						tooltipClass="default-tooltip"
					>
						{{ proButtonText }}
					</a>
				</div>

				@if (!isMobile || (!navigationActive && isMobile)) {
					<leetify-common-notifications-component
						class="notifications"
						[criticalWarnings]="criticalWarnings"
						[followRequests]="followRequests"
						[notifications]="notifications"
						[numberOfUnreadNotifications]="numberOfUnreadNotifications"
						(dropdownToggle)="toggleMobileNav(MenuType.NOTIFICATIONS)"
					></leetify-common-notifications-component>
				}

				<leetify-common-user-menu></leetify-common-user-menu>

			</div>
		} @else {
			<div class="right --unauthenticated">
				<div class="spacer"></div>

				<div class="auth-buttons">
					<a href="{{ homeFrontendUrl }}/auth/login" class="login-button cursor-pointer">Log In</a>
					<a href="{{ homeFrontendUrl }}/auth/signup" class="signup-button">Sign Up</a>
				</div>
			</div>
		}
	</aside>

	<div [ngClass]="{'content-container' : !(isOpen || areNotificationsOpen) }">
		<div [ngClass]="{'blur-background' : isOpen || areNotificationsOpen }" (click)="toggleMobileNav(null)">
			@if (isOpen && !navigationActive) {
				<div class="close-container">
					<button class="close-button" (click)="toggleMobileNav(null)">
						<div class="close-text">Close</div>
						<leetify-icon [icon]="Icon.CIRCLE_CROSS_CLOSE" style="font-size: 2.5rem; color: #CACACC"></leetify-icon>
					</button>
				</div>
			}
		</div>
	</div>

	<div class="bottom-container" [ngClass]="{ 'border-on': isOpen }">
		<div class="bottom-nav">
			<button class="close-button-nav navigation-open" [ngClass]="{'navigation-open': navigationActive && isMobile }" (click)="toggleMobileNav(null)">
				<div class="close-text">Close</div>
				<leetify-icon [icon]="Icon.CIRCLE_CROSS_CLOSE" style="font-size: 2.5rem; color: #CACACC"></leetify-icon>
			</button>

			@if (isMobile) {
				<leetify-common-player-search
					[focusSearchInput$]="focusSearchInput$"
					[ngClass]="{ '--active': searchActive }"
					[isMobile]="isMobile"
					(close)="toggleSearch(null, false)">
				</leetify-common-player-search>

				@if (user) {
					<leetify-common-user-menu
						[toggleOpen]= "activeMenu === MenuType.USER"
					></leetify-common-user-menu>
				}
			}
		</div>
	</div>
	<div class="placeholder-bottom-nav-menu"></div>
</div>

@if (user) {
	<div class="bottom-nav-menu"
		 [ngClass]="{'nav-opened': navigationActive, 'menu-opened' : isOpen }">
		<div class="bottom-menu" [ngClass]="{'--closed': !isOpen }">
			<div class="nav-buttons">
				<!--search-->
				<button class="mobile-search-toggle" aria-label="Toggle Search" title="Toggle Search" (click)="toggleSearch($event, undefined,true)">
					<leetify-icon [icon]="Icon.SEARCH" class="icon-size" [ngClass]="{ '--active': searchActive }"></leetify-icon>
				</button>

				<!--side-nav-->
				<div class="mobile-nav-toggle">
					<button aria-label="Toggle Menu" title="Toggle Menu" (click)="toggleNav($event, true)">
						@if (navigationActive) {
							<leetify-icon [icon]="Icon.X_CROSS_CLOSE" class="icon-size" [ngClass]="{ '--active': navigationActive }"></leetify-icon>
						} @else {
							<leetify-icon [icon]="Icon.MENU" class="icon-size"></leetify-icon>
						}
					</button>
				</div>

				<!--user-menu-->
				<button class="mobile-search-toggle" aria-label="Toggle User" title="Toggle User" (click)="toggleMobileNav(MenuType.USER)">
					<leetify-icon [icon]="Icon.USER_CIRCLE" class="icon-size" [ngClass]="{ '--active': activeMenu === MenuType.USER }"></leetify-icon>
				</button>
			</div>
		</div>
	</div>
}
