import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export type SpectatingUser = {
	avatarUrl: string;
	name: string;
	steam64Id: string;
};

@Injectable({
	providedIn: 'root',
})
export class SpectatingService {
	public isSpectating: boolean;

	private spectatingId: string;
	private spectatingUser: SpectatingUser;

	private spectatingUserSource = new Subject<SpectatingUser>();
	private spectatingIdSource = new Subject<string>();

	public spectatingId$ = this.spectatingIdSource.asObservable();
	public spectatingUser$ = this.spectatingUserSource.asObservable();

	public constructor(
		protected readonly activeRoute: ActivatedRoute,
		protected readonly http: HttpClient,
		protected readonly route: ActivatedRoute,
		protected readonly router: Router,
	) {
		this.activeRoute.queryParams.subscribe(() => {
			this.loadSpectatingId();
		});
	}

	public getSpectatingUser(): SpectatingUser {
		return this.spectatingUser;
	}

	public getSpectatingId(): string {
		if (!this.spectatingId) {
			this.loadSpectatingId();
		}
		return this.spectatingId;
	}

	public loadSpectatingId(): void {
		const spectatingId = this.getSpectatingIdFromParams();
		if (spectatingId === this.spectatingId) {
			return;
		}

		this.spectatingId = spectatingId;
		this.spectatingIdSource.next(spectatingId);
		this.fetchSpectatingUser(spectatingId);
	}

	public fetchSpectatingUser(spectatingId: string): void {
		if (!spectatingId) {
			this.isSpectating = false;
			this.spectatingUser = null;
			this.spectatingUserSource.next(null);
			return;
		}

		try {
			this.http
				.get<SpectatingUser>(`${environment.csApiUrl}/api/user/spectating/${spectatingId}`)
				.subscribe((user: SpectatingUser) => {
					this.isSpectating = !!user;
					this.spectatingUser = user;
					this.spectatingUserSource.next(user);
				});
		} catch {
			this.isSpectating = false;
			this.spectatingUser = null;
			this.spectatingUserSource.next(null);
		}
	}

	public startSpectating(userId: string): void {
		return this.redirectToSpectating(userId, ['/app/general']);
	}

	public redirectToSpectating(userId: string, url: string[]): void {
		const currentRoute = window.location.pathname;

		if (currentRoute.includes('/app')) {
			this.router.navigate(url, {
				queryParamsHandling: 'merge',
				queryParams: {
					spectating: userId,
				},
			});
			return;
		}

		window.location.href = `${environment.csFrontendUrl}/general?spectating=${encodeURIComponent(userId)}`;
	}

	private getSpectatingIdFromParams(): string {
		return this.route.snapshot.queryParams['spectating'];
	}

	public startSpectatingOnCurrentRoute(userId: string): void {
		this.router.navigate([], {
			relativeTo: this.activeRoute,
			queryParamsHandling: 'merge',

			queryParams: {
				spectating: userId,
			},
		});
	}

	public stopSpectating(): void {
		this.spectatingId = this.spectatingUser = null;
		this.spectatingUserSource.next(null);
		this.router.navigate([], {
			queryParams: {
				spectating: null,
			},
			queryParamsHandling: 'merge',
		});
	}
}
