import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AvatarMetadataDTO } from 'leetify-cosmetics-dtos/dto';
import { AvatarStyle, CosmeticId, CosmeticImageType } from 'leetify-cosmetics-dtos/enums';
import { AvatarsService } from 'src/components/services/avatars.service';
import { ViewHelper } from 'src/components/helpers/view.helper';

/**
 * Use this component whenever you want to show a user's or player's avatar, regardless of if there should be any effects applied to it or not.
 */
@Component({
	selector: 'leetify-common-avatar',
	styleUrls: ['./avatar.component.scss'],
	templateUrl: './avatar.component.html',
})
export class CommonAvatarComponent implements OnChanges, OnDestroy, OnInit {
	/** Force the avatar border to the given border. Ignored if `showCosmetics` is `false`. */
	@Input() public borderOverride: CosmeticId;

	/** If set and `isBorderOnlyAnimatedOnHover` is `true`, border will be animated when hovering over `hoverTarget` instead of when hovering over the avatar. */
	@Input() public hoverTarget: HTMLElement;

	/** If `true`, the cosmetic border will be animated. */
	@Input() public isBorderAnimated: boolean = false;

	/** If `true`, and `isBorderAnimated` is also `true`, the cosmetic border will only be animated when hovering over the avatar. Ignored if `isBorderAnimated` is `false`. */
	@Input() public isBorderOnlyAnimatedOnHover: boolean = false;

	/** The Leetify user ID of the person to show the avatar for. */
	@Input() public leetifyUserId: string;

	/** Whether or not to show cosmetics. */
	@Input() public showCosmetics: boolean = false;

	/** Whether or not to show a border indicating "account level", i.e. free/Pro/Founder/Staff. */
	@HostBinding('class.--show-style')
	@Input()
	public showStyle: boolean = false;

	/** The Steam64ID of the person to show the avatar for. Ignored if `leetifyUserId` is also provided. */
	@Input() public steam64Id: string;

	/** Force the given style, i.e. show this instead of the user's actual "account level" (e.g. Pro/Founder). Ignored if `showStyle` is `false`. */
	@Input() public styleOverride: AvatarStyle;

	protected readonly CosmeticImageType = CosmeticImageType;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;

	protected avatarUrl: string;
	protected borderId: CosmeticId = CosmeticId.NONE;
	protected isInitialized = false;
	protected style: string;

	public constructor(
		protected readonly avatarsService: AvatarsService,
	) {
		//
	}

	protected async handleAvatarMetadata(avatarMetadata: AvatarMetadataDTO, forceAccept: boolean = false): Promise<void> {
		if (!this.isRequestedMetadata(avatarMetadata) && !forceAccept) return;

		this.avatarUrl = avatarMetadata.avatarUrl;
		this.borderId = avatarMetadata.borderId;
		this.style = avatarMetadata.style;
	}

	protected isRequestedMetadata(avatarMetadata: AvatarMetadataDTO): boolean {
		if (this.leetifyUserId && avatarMetadata.userId === this.leetifyUserId) return true;
		if (this.steam64Id && avatarMetadata.steam64Id === this.steam64Id) return true;

		return false;
	}

	protected reloadAvatarMetadata(): void {
		if (this.leetifyUserId) {
			this.avatarsService.getAvatarByLeetifyUserId(this.leetifyUserId);
		} else if (this.steam64Id) {
			this.avatarsService.getAvatarBySteam64Id(this.steam64Id);
		} else {
			this.handleAvatarMetadata(this.avatarsService.getDefaultMetadata(), true);
		}
	}

	public ngOnChanges(): void {
		if (!this.isInitialized) return; // avoids sending metadata request before the listener is set up (which can easily cause issues when data is already cached)

		this.reloadAvatarMetadata();
	}

	public ngOnInit(): void {
		this.handleAvatarMetadata(this.avatarsService.getDefaultMetadata(), true); // set to default on page load to avoid things breaking

		this.avatarsService.avatarMetadata$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((avatarMetadata) => this.handleAvatarMetadata(avatarMetadata));

		this.reloadAvatarMetadata();

		this.isInitialized = true;
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
