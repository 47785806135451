import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchResultDTO, SuggestedUsersDTO } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	protected readonly recentSearchResultsSource = new Subject<SearchResultDTO[]>();
	public readonly recentSearchResults$ = this.recentSearchResultsSource.asObservable();

	private readonly suggestedUsersSource = new Subject<SuggestedUsersDTO>();
	public readonly suggestedUsers$ = this.suggestedUsersSource.asObservable();

	public constructor(
		protected readonly http: HttpClient,
	) {
	}

	public async searchCS(query: string): Promise<SearchResultDTO[]> {
		try {
			return await this.http.post<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/users`, { query }).toPromise();
		} catch (err) {
			console.error(err);
			return [];
		}
	}

	public async searchLoL(query: string): Promise<SearchResultDTO[]> {
		try {
			return await this.http.post<SearchResultDTO[]>(`${environment.lolApiUrl}/api/search/users`, { query }).toPromise();
		} catch (err) {
			console.error(err);
			return [];
		}
	}

	public reloadRecentSearchResults(): void {
		this.http.get<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/recent-search-results`).subscribe((results) => this.recentSearchResultsSource.next(results));
	}

	public saveRecentSearchResult(result: SearchResultDTO): void {
		this.http.post<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/recent-search-result/${result.userId}`, {}).subscribe((results) => this.recentSearchResultsSource.next(results));
	}

	public removeRecentSearchResult(result: SearchResultDTO): void {
		this.http.delete<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/recent-search-result/${result.userId}`).subscribe((results) => this.recentSearchResultsSource.next(results));
	}

	public reloadSuggestedUsers(): void {
		this.http.get<SuggestedUsersDTO>(`${environment.homeApiUrl}/api/suggestions`).subscribe(
			(suggested: SuggestedUsersDTO) => {
				this.suggestedUsersSource.next(suggested);
				return suggested;
			},
			(e) => {
				console.log(e);
				return null;
			},
		);
	}
}
