import { AuthService } from 'src/app/auth/auth.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ProgressReportQuarter } from 'leetify-shared-utils/enums';
import { Router, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/components/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { environment } from 'src/environments/environment';
import { ProgressReportsHelper } from 'leetify-shared-utils/progress-reports-helper';
import { ViewHelper } from 'src/components/helpers/view.helper';

interface AvailableProgressReportViewModel {
	routerLink: (string | number)[];
	title: string;
	href: string;
}

interface AvailableProgressReportsYearViewModel {
	heading: string | number;
	reports: AvailableProgressReportViewModel[];
}

@Component({
	selector: 'leetify-common-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	host: {
		'(document:click)': 'onClick($event)',
	},
})
export class CommonUserMenuComponent implements OnDestroy, OnChanges, OnInit {
	@Output() public readonly dropdownToggle = new EventEmitter<boolean>();
	@Input() toggleOpen: boolean = false;

	protected readonly csFrontendUrl = environment.csFrontendUrl;
	protected readonly homeFrontendUrl = environment.homeFrontendUrl;
	protected readonly Icon = Icon;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly PATH_PRO = `${environment.csFrontendUrl}/pro`;

	protected availableAnnualRecaps: AvailableProgressReportViewModel[];
	protected availableProgressReports: AvailableProgressReportsYearViewModel[];
	protected currentApp: string;
	protected displayName: string;
	protected isAnnualRecapsSubmenuExpanded = false;
	protected isMenuActive = false;
	protected isProgressReportsSubmenuExpanded = false;
	protected user: User;

	protected proButtonText = 'Get Pro';
	protected proButtonClass = 'btn-primary';

	public constructor(
		protected readonly authService: AuthService,
		protected readonly _eref: ElementRef,
		protected readonly router: Router,
		protected readonly userService: UserService,
	) {
		//
	}

	protected onClick(e: PointerEvent): void {
		if (!this._eref.nativeElement.contains(e.target)) {
			// or some similar check
			if (!this.toggleOpen) {
				this.toggleDropdown(false);
			}
		}
	}

	protected toggleDropdown(isActive = !this.isMenuActive): void {
		this.isMenuActive = isActive;

		if (!isActive) {
			this.toggleAnnualRecapsSubmenu(false);
			this.toggleProgressReportsSubmenu(false);
		}
	}

	protected toggleAnnualRecapsSubmenu(isExpanded = !this.isAnnualRecapsSubmenuExpanded): void {
		this.isAnnualRecapsSubmenuExpanded = isExpanded;
	}

	protected toggleProgressReportsSubmenu(isExpanded = !this.isProgressReportsSubmenuExpanded): void {
		this.isProgressReportsSubmenuExpanded = isExpanded;
	}

	protected logout(): void {
		this.authService.logout();
		window.location.href = '/';
	}

	private setProButton(user: User): void {
		if (!user) return;

		this.proButtonClass = 'btn-primary';
		this.proButtonText = 'Get Pro';

		if (user.isProPlan) {
			this.proButtonClass = 'btn-primary';
			this.proButtonText = 'My Pro';
		}

		if (user.isCollector) {
			this.proButtonClass = 'btn-founder';
		}
	}

	protected handleUser(user: User): void {
		this.user = user;
		this.setAvailableProgressReports();
		this.setProButton(user);

		this.displayName = this.getDisplayName(user);
	}

	// TODO may at some point want to change this to just use the Leetify nickname, no matter what
	protected getDisplayName(user: Record<string, any>): string {
		const path = typeof window !== 'undefined' && window.location.pathname;
		if (path?.startsWith('/lol')) {
			return user.nickname || (user.userName && user.userTag ? `${user.userName}#${user.userTag}` : 'Noname');
		}

		return user.nickname || user.steamNickname || user.faceitNickname || 'Noname';
	}

	public goToPage(location: string) {
		window.location.href = `${environment.csFrontendUrl}/${location}`;
	}

	public goToPageHome(location: string) {
		window.location.href = `${environment.homeFrontendUrl}/${location}`;
	}

	protected goToProfile() {
		window.location.href = `${environment.csFrontendUrl}/profile/${this.user.steam64Id || ''}`;
	}

	protected setAvailableProgressReports(): void {
		const id = this.user.id;

		const reports = ProgressReportsHelper.getAvailableProgressReports({
			steam64Id: this.user.steam64Id,
			userCreatedAt: new Date(this.user.signUpDate),
			userId: id,
		});

		const annualRecaps: AvailableProgressReportViewModel[] = [];
		const progressReports: AvailableProgressReportsYearViewModel[] = [];

		for (const report of reports) {
			const link = ProgressReportsHelper.getUrlSegments(report);

			let href = '';

			if (this.currentApp !== 'cs') {
				href = link.join('/');
			}

			const reportViewModel: AvailableProgressReportViewModel = {
				routerLink: ProgressReportsHelper.getUrlSegments(report),
				title: ProgressReportsHelper.getProgressReportTitleWithoutNickname(report.year, report.quarter),
				href,
			};

			if (report.quarter === ProgressReportQuarter.ANNUAL) {
				annualRecaps.push(reportViewModel);
			} else {
				if (!progressReports.length || progressReports[progressReports.length - 1].heading !== report.year) {
					progressReports.push({ heading: report.year, reports: [] });
				}

				progressReports[progressReports.length - 1].reports.push(reportViewModel);
			}
		}

		this.availableAnnualRecaps = annualRecaps;
		this.availableProgressReports = progressReports;
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);

		// close menu after clicking any link
		this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.toggleDropdown(false));

		const path = window.location.pathname;

		if (path.startsWith('/app')) {
			this.currentApp = 'cs';
		}
	}

	public ngOnChanges() {
		if (this.toggleOpen) {
			this.toggleDropdown(this.toggleOpen);
		}
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
