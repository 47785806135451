export * from './enums/clubs';
export * from './enums/game-d2d-replay';
export * from './enums/practice-servers';
export * from './enums/progress-reports';

export enum GameUserHighlightStatusDto {
	PENDING_PRO = 'pending_pro',
	COMPLETED = 'completed',
	SUBMITTED = 'submitted',
	PROCESSING = 'processing',
	PROCESSING_LOW_PRIO = 'processing_low_prio',
	DISABLED_CLIPPING = 'disabled_clipping',
}

export enum PosthogTrackingValue {
	SKILL_DETAILS = 'skill-details',
	MATCH_DETAILS = 'match-details',
	MATCH_LIST = 'match-list',
	SESSIONS_VISITED = 'sessions-visited',
	SESSIONS_PLAYER_FOCUSED = 'sessions-player-focused',
	SESSIONS_HIGHLIGHTS_EXPANDED = 'sessions-highlights-expanded',
	COMPARE = 'compare',
	DATA_SOURCES = 'datasources',
	DASHBOARD = 'dashboard',
	AIM_GROUP = 'aim-group',
	UTILITY_GROUP = 'utility-group',
	MAP_LIST = 'map-list',
	MAP_DETAILS = 'map-details',
	MANUAL_DEMO_UPLOAD = 'manual_demo_upload',
	GENERAL_TAB = 'general-tab',
	PRACTICE_SERVERS = 'practice-servers',
	REFERRALS = 'referrals',
	MAP_FLASH_TOOL = 'map-flash-tool',
	MAP_SMOKE_TOOL = 'map-smoke-tool',
	STEAM_BOT_ADD = 'steam-bot-add',
	PROFILE = 'profile',
	ONBOARDING_STARTED = 'onboarding-started',
	ONBOARDING_STEAM_CONNECTED = 'onboarding-steam-connected',
	ONBOARDING_EMAIL_SET = 'onboarding-email-set',
	ONBOARDING_MATCHMAKING_CONNECTED = 'onboarding-matchmaking-connected',
	ONBOARDING_FACEIT_CONNECTED = 'onboarding-faceit-connected',
	ONBOARDING_ESPORTAL_CONNECTED = 'onboarding-esportal-connected',
	ONBOARDING_GAMERS_CLUB_CONNECTED = 'onboarding-gamers-club-connected',
	ONBOARDING_DISCORD_LINK_CLICKED = 'onboarding-discord-link-clicked',
	ONBOARDING_COMPLETED = 'onboarding-completed',
	ONBOARDING_STEAM_BOT_CONNECTED = 'onboarding-steam-bot-connected',
	CLUBS_PRO_PLANS_VISITED = 'clubs-pro-plans-visited',
	CLUBS_DASHBOARD_VISITED = 'clubs-dashboard-visited',
	CLUBS_CREATE_VISITED = 'clubs-create-visited',
	CLUBS_MANAGE_VISITED = 'clubs-manage-visited',
	CLUBS_JOIN_BY_LINK_VISITED = 'clubs-join-by-link-visited',
	CLUBS_MAPS_VISITED = 'clubs-maps-visited',
	CLUBS_MATCHES_VISITED = 'clubs-matches-visited',
	CLUBS_MAPS_ZONES_VISITED = 'clubs-maps-zones-visited',
	CLUBS_FLASH_TOOL_VISITED = 'clubs-flash-tool-visited',
	CLUBS_SMOKE_TOOL_VISITED = 'clubs-smoke-tool-visited',
	CLUBS_TEAMPLAY_DUELS_VISITED = 'clubs-teamplay-duels-visited',
	CLUBS_TEAMPLAY_SIDE_VISITED = 'clubs-teamplay-side-visited',
	CLUBS_TEAMPLAY_SITUATION_VISITED = 'clubs-teamplay-situation-visited',
	CLUBS_MEMBER_COMPARISON_VISITED = 'clubs-member-comparison-visited',
	CLUBS_MAP_STATS_VISITED = 'clubs-map-stats-visited',
	CLUBS_CREATED = 'clubs-created',
	CLUBS_DELETED = 'clubs-deleted',
	CLUBS_INFO_UPDATED = 'clubs-info-updated',
	CLUBS_MEMBER_SPECTATED = 'clubs-member-spectated',
	CLUBS_MEMBER_REMOVED = 'clubs-member-removed',
	CLUBS_INVITE_SENT = 'clubs-invite-sent',
	CLUBS_INVITE_ACCEPTED = 'clubs-invite-accepted',
	CLUBS_INVITE_DELETED = 'clubs-invite-deleted',
	CLUBS_MEMBERSHIP_REQUEST_SENT = 'clubs-membership-request-sent',
	CLUBS_MEMBERSHIP_REQUEST_ACCEPTED = 'clubs-membership-request-accepted',
	CLUBS_MEMBERSHIP_REQUEST_DELETED = 'clubs-membership-request-deleted',
	CLUBS_JOINED_BY_LINK = 'clubs-joined_by_link',
	CLUBS_LEFT = 'clubs-left',
	CLUBS_OWNERSHIP_TRANSFER = 'clubs-ownership-transfer',
	CLUBS_MY_CLUBS_PAGE_VISITED = 'clubs-my-clubs-page-visited',
	CLUBS_LEADERBOARD_VISITED = 'clubs-leaderboards-visited',
	MATCH_LABEL_ADDED_MANUALLY = 'match-label-added-manually',
	MATCH_LABEL_EDITED_MANUALLY = 'match-label-edited-manually',
	MATCH_LABEL_DELETED_MANUALLY = 'match-label-deleted-manually',
	MATCH_LABEL_DEFAULT_FILTER_CHANGED = 'match-label-default-filter-changed',
	MATCH_LABEL_APPLIED_TO_MATCH = 'match-label-applied-to-match',
	FILTER_BY_MATCH_LABELS = 'filter-by-match-labels',
	D2D_VIEWER_VISITED = '2d-viewer-visited',
	FOCUS_AREAS_PAGE_VISITED = 'focus-areas-page-visited',
	CONTENT_NOTIFICATION_CLICKED = 'content-notification-clicked',
	CONTENT_NOTIFICATION_DISMISSED = 'content-notification-dismissed',
	CRITICAL_WARNING_LINK_CLICKED = 'critical-warning-link-clicked',
	CRITICAL_WARNING_DISMISSED = 'critical-warning-dismissed',
	CLUBS_SESSIONS_VISITED = 'clubs-sessions-visited',
	PROFILE_VISITED = 'profile-visited',
	PMJ_CUSTOM_PROMPTS_VISITED = 'pmj-custom-prompts-visited',
	PMJ_AUTO_PROMPTS_VISITED = 'pmj-auto-prompts-visited',
	PMJ_PROMPTS_ARCHIVE_VISITED = 'pmj-prompts-archive_visited',
	PMJ_PROMPT_CREATED = 'pmj-prompt-created',
	PMJ_PROMPT_ARCHIVED = 'pmj-prompt-archived',
	PMJ_PROMPT_RESTORED = 'pmj-prompt-restored',
	PMJ_PROMPT_DELETED = 'pmj-prompt-deleted',
	PMJ_PROMPT_ENTRIES_SUBMITTED = 'pmj-prompt-entries-submitted',
	PMJ_PROMPT_ENTRIES_UPDATED = 'pmj-prompt-entries-updated',
	INTERACTED_WITH_RECAP = 'interacted-with-recap',
	MATCH_DETAILS_FREE_D2D_VISITED = 'match-details-free-d2d-visited',
	FREE_D2D_REPLAY_REQUESTED = 'free-d2d-replay-requested',
	CLICKED_GET_PRO_CTA = 'clicked-get-pro-cta',
	CLICKED_GET_PRO_LEARN_MORE_CTA = 'clicked-get-pro-learn-more-cta',

	CS_ONBOARDING_STARTED = 'cs-onboarding-started',
	CS_ONBOARDING_STEAM_CONNECTED = 'cs-onboarding-steam-connected',
	CS_ONBOARDING_EMAIL_SET = 'cs-onboarding-email-set',
	CS_ONBOARDING_MATCHMAKING_CONNECTED = 'cs-onboarding-matchmaking-connected',
	CS_ONBOARDING_FACEIT_CONNECTED = 'cs-onboarding-faceit-connected',
	CS_ONBOARDING_ESPORTAL_CONNECTED = 'cs-onboarding-esportal-connected',
	CS_ONBOARDING_GAMERS_CLUB_CONNECTED = 'cs-onboarding-gamers-club-connected',
	CS_ONBOARDING_DISCORD_LINK_CLICKED = 'cs-onboarding-discord-link-clicked',
	CS_ONBOARDING_COMPLETED = 'cs-onboarding-completed',
	CS_ONBOARDING_STEAM_BOT_CONNECTED = 'cs-onboarding-steam-bot-connected',

	LANDING_CAREERS = 'landing-careers-visited',
	LANDING_TOP_SIGNUP = 'landing-top-signup-clicked',
	LANDING_BOTTOM_SIGNUP = 'landing-bottom-signup-clicked',
	MASTHEAD_SIGNUP = 'mast-landing-signup-clicked',
	MASTHEAD_SIDE_SIGNUP = 'mast-side-landing-signup-clicked',
	MASTHEAD_SIDE_LOGIN = 'mast-side-landing-login-clicked',
	LOGGED_IN_USER_REDIRECT_FROM_HOME = 'logged-in-user-redirect-from-home',
	VISIT_HOME = 'visit-home',
	VISIT_LANDING_HOME = 'visit-landing-home',
	LANDING_GAMES_CS_SIGNUP = 'landing-games-cs-signup',
	LANDING_GAMES_LEAGUE = 'landing-games-league',
	LANDING_GAMES_DEADLOCK = 'landing-games-deadlock',

	LOL_LANDING_CAREERS = 'lol-landing-careers-visited',
	LOL_LANDING_TOP_SIGNUP = 'lol-landing-top-signup-clicked',
	LOL_LANDING_BOTTOM_SIGNUP = 'lol-landing-bottom-signup-clicked',
	LOL_MASTHEAD_SIGNUP = 'lol-mast-landing-signup-clicked',
	LOL_MASTHEAD_SIDE_SIGNUP = 'lol-mast-side-landing-signup-clicked',
	LOL_MASTHEAD_SIDE_LOGIN = 'lol-mast-side-landing-login-clicked',
	LOL_VISIT_LANDING_HOME = 'lol-visit-landing-home',
	LOL_LANDING_MATCH_DETAILS = 'lol-landing-match-details',
	LOL_LANDING_RUNES_AND_BUILDS = 'lol-landing-runes-and-builds',
	LOL_LANDING_INCOME = 'lol-landing-income',
	LOL_LANDING_OBJECTIVES = 'lol-landing-objectives',
	LOL_LANDING_DAMAGE = 'lol-landing-damage',
	LOL_LANDING_HEALING = 'lol-landing-healing',
	LOL_LANDING_OVERVIEW = 'lol-landing-overview',
	LOL_LANDING_GENERAL = 'lol-landing-general',
	LOL_LANDING_GAMES_CS = 'lol-landing-games-cs',
	LOL_LANDING_GAMES_LEAGUE_SIGNUP = 'lol-landing-games-league_signup',
	LOL_LANDING_GAMES_DEADLOCK = 'lol-landing-games-deadlock',

	RENOWN_INVITE_CLICKED_TOAST = 'renown-invite-clicked-toast',
	RENOWN_INVITE_DISMISSED_TOAST = 'renown-invite-dismissed-toast',
	RENOWN_INVITE_CLICKED_BANNER = 'renown-invite-clicked-banner',
	RENOWN_INVITE_DISMISSED_BANNER = 'renown-invite-dismissed-banner',

	PAGEVIEW = '$pageview',
	PAGELEAVE = '$pageleave',
}

export enum MapName {
	CS_AGENCY = 'cs_agency',
	CS_OFFICE = 'cs_office',
	DE_ANCIENT = 'de_ancient',
	DE_ANUBIS = 'de_anubis',
	DE_CACHE = 'de_cache',
	DE_DUST2 = 'de_dust2',
	DE_INFERNO = 'de_inferno',
	DE_MIRAGE = 'de_mirage',
	DE_NUKE = 'de_nuke',
	DE_OVERPASS = 'de_overpass',
	DE_TRAIN = 'de_train',
	DE_TUSCAN = 'de_tuscan',
	DE_VERTIGO = 'de_vertigo',
}

export enum ScenarioName {
	TAKE = 'take',
	RETAKE = 'retake',
	HOLD = 'hold',
	AFTERPLANT = 'afterplant',
}

export enum TradeScenarioName {
	KILL = 'kill',
	DEATH = 'death',
}

export enum BombsiteName {
	A = 0,
	B = 1,
}

export enum TeamName {
	TERRORIST = 2,
	COUNTER_TERRORIST = 3
}

export enum MatchResult {
	WIN = 'win',
	LOSS = 'loss',
	TIE = 'tie',
}

export enum MatchOutcome {
	WIN = 2,
	LOSS = 0,
	TIE = 1,
}

export enum GameStatus {
	DRAFT = 'draft',
	NEW = 'new',
	SCHEDULED = 'scheduled',
	DOWNLOADING = 'downloading',
	PROCESSING = 'processing',
	READY_BAD_DATA = 'ready_bad_data',
	READY = 'ready',
	ERROR = 'error',
	QUEUED = 'queued',
	DELETED = 'deleted',
}

export enum OnboardingStatus {
	NEW = 'new',
	EMAIL_REQUIRED = 'email_required',
	ONBOARDING = 'onboarding',
	COMPLETED = 'completed',
	STEAM_ACCOUNT = 'steam_account',
	DATA_SOURCE = 'data_source',
	NOTIFICATIONS = 'notifications',
}

export enum OnboardingStates {
	STEAM = 'steam',
	EMAIL = 'email',
	DATA_SOURCES = 'datasources',
	MATCHMAKING = 'matchmaking',
	ESPORTAL = 'esportal',
	STEAM_BOT = 'steambot',
	DISCORD = 'discord',
}

export enum ReferralStatus {
	NEW = 'new',
	DATA_SOURCE_CONNECTED = 'data_source_connected',
	DUPLICATE_SOURCE_CONNECTED = 'duplicate_source_connected',
	COMPLETED = 'completed',
}

export enum GameVersion {
	CS2 = 'cs2',
	CSGO = 'csgo',
}

export enum DataSource {
	MATCHMAKING = 'matchmaking',
	MATCHMAKING_WINGMAN = 'matchmaking_wingman',
	FACEIT = 'faceit',
	HLTV = 'hltv',
	MANUAL = 'manual',
	LEETIFY_MATCH = 'leetify_match',
	ESPORTAL = 'esportal',
	GAMERS_CLUB = 'gamers_club',
	RENOWN = 'renown',
}

export enum PrivacySettingsMode {
	PUBLIC = 'public',
	FRIENDS = 'friends',
	PRIVATE = 'private',
}

export enum ErrorCode {
	TIMEOUT = 'timeout',
	PARSE_ERROR = 'parse_error',
	UNKNOWN_ERROR = 'unknown_error',
	DOWNLOAD_ERROR = 'download_error',
	DOWNLOAD_NOT_AVAILABLE = 'download_not_available',
	MISSING_API_DATA = 'missing_api_data',
	STEAM_API_ERROR = 'steam_api_error',
	FACEIT_API_ERROR = 'faceit_api_error',
	PROXY_BLOCKED_ERROR = 'proxy_blocked_error',
	CUSTOMMAP_GAMESERVER_ERROR = 'custommap_gameserver_error',
	GAMESERVER_CONNECTIVITY_ERROR = 'gameserver_connectivity_error',
}

export enum EnvName {
	DEVELOPMENT = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export enum EventsGroupingFilter {
	USER = 'user',
	TEAM = 'team',
}

export enum BombPlantEventFilter {
	PRE = 'pre',
	POST = 'post',
	ALL = 'all'
}

export enum ProductName {
	PRO = 'pro',
	COLLECTOR = 'collector',
	UNLIMITED = 'unlimited',
	RENOWN_BUNDLE = 'renown_bundle',
}

export enum SubscriptionStatus {
	ACTIVE = 'active',
	CANCELED = 'canceled',
	INCOMPLETE = 'incomplete',
	INCOMPLETE_EXPIRED = 'incomplete_expired',
	TRIALING = 'trialing',
	PAST_DUE = 'past_due',
	UNPAID = 'unpaid',
	PAUSED = 'paused',
}

export enum SubscriptionDiscountIds {
	SUPER_EARLY_BIRD = 'super_early_bird',
	EARLY_BIRD = 'early_bird',
	NO_DISCOUNT = 'no_discount',
}

export enum SubscriptionInterval {
	MONTHLY = 'monthly',
	ANNUAL = 'annual',
}

export enum ReferralRewards {
	REWARD_PRO_DAYS = 14,
}

export enum ImporterConstants {
	ENEMIES_AROUND_SMOKE_RADIUS = 800,
	SMOKE_EXTINGUISH_INFERNO_RADIUS = 150,
	TRADE_ATTEMPTS_RADIUS = 200,
	TRADE_TIMEOUT_SECONDS = 4
}

export enum RoundEndReason {
	TARGET_BOMBED = 1,
	VIP_ESCAPED = 2,
	VIP_KILLED = 3,
	TERRORISTS_ESCAPED = 4,
	CT_STOPPED_ESCAPE = 5,
	T_STOPPED = 6,
	BOMB_DEFUSED = 7,
	CT_WIN = 8,
	T_WIN = 9,
	DRAW = 10,
	HOSTAGES_RESCUED = 11,
	TARGET_SAVED = 12,
	HOSTAGES_NOT_RESCUED = 13,
	T_NOT_ESCAPED = 14,
	VIP_NOT_ESCAPED = 15,
	GAME_START = 16,
	T_SURRENDER = 17,
	CT_SURRENDER = 18,
}

export enum RoundResult {
	// from https://gitlab.com/scriptkid/csgo-fake-cheat-public/-/blob/1487af36277f6b6fb3de9d3ccc206e782ca1916c/ScriptKidAntiCheat%20-%20CSGO%20Edition/Classes/Internal/MatchInfo.cs#L284-295
	ROUND_INCOMPLETE = 0,
	CT_WIN_ELIMINATION = 1,
	CT_WIN_HOSTAGE_RESCUED = 2,
	CT_WIN_BOMB_DEFUSED = 3,
	CT_WIN_TIME_RAN_OUT = 4,
	T_WIN_ELIMINATION = 5,
	T_WIN_BOMB_EXPLODED = 6,
	T_WIN_TIME_RAN_OUT = 7,
}

export enum SkillCardWidgetSideClass {
	CT = 'ct',
	T = 't',
	GREAT = 'great',
	POOR = 'poor',
	SECONDARY = 'secondary',
	PRIMARY = 'primary',
}

export enum AutomaticGameLabelName {
	BANNED_PLAYER = 'Banned Player',
	ONE_VS_ONE = '1v1',
	WINGMAN = 'Wingman',
}

export enum DiscordEventType {
	PLAYED_WITH_PRO = 'PLAYED_WITH_PRO',
	MATCHMAKING_UPRANK = 'MATCHMAKING_UPRANK',
	FACEIT_UPRANK = 'FACEIT_UPRANK',
	GAMERSCLUB_UPRANK = 'GAMERS_CLUB_UPRANK',
	KILLS = 'KILLS',
	KD_RATIO = 'KD_RATIO',
	DEATHS = 'DEATHS',
	KPR = 'KPR',
	ADR = 'ADR',
	ENEMIES_FLASHED = 'ENEMIES_FLASHED',
	HE_DAMAGE = 'HE_DAMAGE',
	WIN_TO_ZERO = 'WIN_TO_ZERO',
	ACES = 'ACES',
	QUAD_KILLS = 'QUAD_KILLS',
}

export enum LoadoutEquipmentTypes {
	NONE,
	KEVLAR = 1,
	HELMET = 2,
	DEFUSER = 4
}

export enum RoundEconomyType {
	PISTOL = 1,
	ECO = 2,
	SEMIECO = 3,
	FORCE = 4,
	FULL = 5
}

export enum ClanTagConstants {
	LEETIFY = 'Leetify'
}

export enum SignupResponseError {
	MISSING_EMAIL = 'The email is missing',
	INVALID_EMAIL = 'The email is invalid or already exists',
	MISSING_PASSWORD = 'The password is missing',
	UNKNOWN_ERROR = 'There was an error during the signup. Please try again',
}

export enum Weapon {
	AK47 = 'weapon_ak47',
	AUG = 'weapon_aug',
	AWP = 'weapon_awp',
	AXE = 'weapon_axe',
	BAYONET = 'weapon_bayonet',
	BIZON = 'weapon_bizon',
	BREACHCHARGE = 'weapon_breachcharge',
	BUMPMINE = 'weapon_bumpmine',
	C4 = 'weapon_c4',
	CZ75AUTO = 'weapon_cz75a',
	DESERT_EAGLE = 'weapon_deagle',
	DECOY_GRENADE = 'weapon_decoy',
	DIVERSION_GRENADE = 'weapon_diversion',
	DUAL_BERETTAS = 'weapon_elite',
	FAMAS = 'weapon_famas',
	FIREBOMB = 'weapon_firebomb',
	FISTS = 'weapon_fists',
	FIVE_SEVEN = 'weapon_fiveseven',
	FLASHBANG = 'weapon_flashbang',
	FRAG_GRENADE = 'weapon_frag_grenade',
	G3SG1 = 'weapon_g3sg1',
	GALIL_AR = 'weapon_galilar',
	GLOCK = 'weapon_glock',
	HAMMER = 'weapon_hammer',
	HEALTH_SHOT = 'weapon_healthshot',
	HE_GRENADE = 'weapon_hegrenade',
	P2000 = 'weapon_hkp2000',
	INCENDIARY_GRENADE = 'weapon_incgrenade',
	INFERNO = 'weapon_inferno',
	KNIFE_BUTTERFLY = 'weapon_knife_butterfly',
	KNIFE_SURVIVAL = 'weapon_knife_canis',
	KNIFE_PARACORD = 'weapon_knife_cord',
	KNIFE_CLASSIC = 'weapon_knife_css',
	KNIFE_FALCHION = 'weapon_knife_falchion',
	KNIFE_FLIP = 'weapon_knife_flip',
	KNIFE_GHOST = 'weapon_knife_ghost',
	KNIFE_GUT = 'weapon_knife_gut',
	KNIFE_NAVAJA = 'weapon_knife_gypsy_jackknife',
	KNIFE_KARAMBIT = 'weapon_knife_karambit',
	KNIFE_M9_BAYONET = 'weapon_knife_m9_bayonet',
	KNIFE_NOMAD = 'weapon_knife_outdoor',
	KNIFE_SHADOW_DAGGERS = 'weapon_knife_push',
	KNIFE_SKELETON = 'weapon_knife_skeleton',
	KNIFE_STILETTO = 'weapon_knife_stiletto',
	KNIFE_SURVIVAL_BOWIE = 'weapon_knife_survival_bowie',
	KNIFE_T = 'weapon_knife_t',
	KNIFE_HUNTSMAN = 'weapon_knife_tactical',
	KNIFE_URSUS = 'weapon_knife_ursus',
	KNIFE_TALON = 'weapon_knife_widowmaker',
	KNIFE = 'weapon_knife',
	KNIFE_GUNGAME = 'weapon_knifegg',
	M249 = 'weapon_m249',
	M4A1S_SILENCER_OFF = 'weapon_m4a1_silencer_off',
	M4A1S = 'weapon_m4a1_silencer',
	M4A4 = 'weapon_m4a1',
	MAC10 = 'weapon_mac10',
	MAG7 = 'weapon_mag7',
	MOLOTOV = 'weapon_molotov',
	MP5 = 'weapon_mp5sd',
	MP7 = 'weapon_mp7',
	MP9 = 'weapon_mp9',
	NEGEV = 'weapon_negev',
	NOVA = 'weapon_nova',
	P250 = 'weapon_p250',
	P90 = 'weapon_p90',
	REVOLVER = 'weapon_revolver',
	SAWED_OFF = 'weapon_sawedoff',
	SCAR20 = 'weapon_scar20',
	SG553 = 'weapon_sg556',
	RIOT_SHIELD = 'weapon_shield',
	SMOKE_GRENADE = 'weapon_smokegrenade',
	SNOWBALL = 'weapon_snowball',
	SPANNER = 'weapon_spanner',
	SSG08 = 'weapon_ssg08',
	TABLET = 'weapon_tablet',
	TAG_GRENADE = 'weapon_tagrenade',
	TASER = 'weapon_taser',
	TEC9 = 'weapon_tec9',
	UMP45 = 'weapon_ump45',
	USP_SILENCER_OFF = 'weapon_usp_silencer_off',
	USP = 'weapon_usp_silencer',
	WORLD = 'weapon_world',
	XM1014 = 'weapon_xm1014',
}

export enum PubsubChannelName {
	GAME_PARSED_AND_SAVED = 'GAME_PARSED_AND_SAVED',
	STEAM_ACCOUNT_CONNECTED = 'STEAM_ACCOUNT_CONNECTED',
	USER_CONVERTED_TO_PRO = 'USER_CONVERTED_TO_PRO',
}

export enum SteamPracticeRecommendationType {
	ENCOURAGEMENT = 'encouragement',
	FOCUS_AREA = 'focus_area',
	GRENADE = 'grenade'
}

export enum UserPreferenceSettingName {
	STEAM_PRACTICE_FREQUENCY = 'steam_practice_frequency'
}

export enum WebsocketEvent {
	AUTHENTICATE = 'authenticate',
	FREE_PRO_RECEIVED = 'free_pro_received',
	GAME_D2D_IMPORT_STATUS = 'game-d2d-import-status',
	GAME_IMPORT_STATUS = 'game-import-status',
	HIGHLIGHT_STATUS_CHANGE = 'highlight-status-change',
	IMPORT_QUEUE_STATUS = 'import-queue-status',
	NEW_GAME_AVAILABLE = 'new-game-available',
	PRACTICE_SERVER_DEMO_UPLOAD_PROGRESS = 'practice-server-demo-upload-progress',
	SYNC_GAMES = 'sync-games',
}

export enum ImportQueueLoad {
	HEAVY = 'heavy',
	NORMAL = 'normal',
}

export enum AchievementTier {
	S = 0,
	A = 1,
	B = 2,
	C = 3,
}

export enum CriticalWarningStyle {
	DANGER = 'danger',
	WARNING = 'warning',
	SUCCESS = 'success',
}

export enum ContentNotificationType {
	TOAST = 'toast',
	MODAL = 'modal',
}

export enum GamePlayerRatingsCategory {
	AFTERPLANTS = 'Afterplants',
	CLUTCHES = 'Clutches',
	DAMAGE = 'Damage Assists',
	ENEMY_SAVING = 'Enemy Saving',
	FLASHES = 'Flash Assists',
	MID_ROUND_KD = 'Mid-round K/D',
	OPENING_DUELS = 'Opening Duels',
	RETAKES = 'Retakes',
	SAVING = 'Saving',
	TRADED = 'Traded',
	UNTRADED_DEATHS = 'Untraded Deaths',
}

export enum GameTimelineType {
	AWP_KILLS = 'awp-kills',
	DAMAGE = 'damage',
	DEATHS = 'deaths',
	ENEMIES_FLASHED = 'enemies-flashed',
	KILLS = 'kills',
	ROUND_DIFFERENCE = 'round-difference',
	TEAM_ECONOMY = 'team-economy',
}

export enum PlayerColorCsgo {
	YELLOW = 0,
	PURPLE,
	GREEN,
	BLUE,
	ORANGE,
}

export enum PlayerColorCs2 {
	BLUE = 0,
	GREEN,
	YELLOW,
	ORANGE,
	PURPLE,
}

export enum PlayerColorAbsolute {
	BLUE = 'blue',
	GREEN = 'green',
	ORANGE = 'orange',
	PURPLE = 'purple',
	YELLOW = 'yellow',
}

export enum HttpResponseCode {
	PAYWALL_GAMES_LIMIT_REACHED = 444,
}

export enum SessionAwardId {
	ALWAYS_THERE = 'always_there',
	CAFFEINATED = 'caffeinated',
	CLUTCH = 'clutch',
	CT_SPECIALIST = 'ct_specialist',
	DEFUSER = 'defuser',
	HARD_CARRY = 'hard_carry',
	INITIATOR = 'initiator',
	INVINCIBLE = 'invincible',
	MVP = 'mvp',
	OVERPERFORMER = 'overperformer',
	SABOTEUR = 'saboteur',
	T_SPECIALIST = 't_specialist',
	UTILITY_BELT = 'utility_belt',
}

export enum PersonalBestSkillId {
	ACCURACY_ENEMY_SPOTTED = 'accuracy_enemy_spotted',
	ACCURACY_HEAD = 'accuracy_head',
	ADR = 'adr',
	AIM_RATING = 'aim_rating',
	CLUTCHES_WON = 'clutches_won',
	CROSSHAIR_PLACEMENT = 'crosshair_placement',
	CT_LEETIFY_RATING = 'ct_leetify_rating',
	ENEMIES_FLASHED = 'enemies_flashed',
	ENEMIES_FLASHED_PER_FLASHBANG = 'enemies_flashed_per_flashbang',
	FLASHBANG_LEADING_TO_KILL_PER_FLASHBANG = 'flashbang_leading_to_kill_per_flashbang',
	HE_FOES_DAMAGE_AVG = 'he_foes_damage_avg',
	KILLS = 'kills',
	LEETIFY_RATING = 'leetify_rating',
	OPENING_DUELS_WON = 'opening_duels_won',
	T_LEETIFY_RATING = 't_leetify_rating',
	TIME_TO_DAMAGE = 'time_to_damage',
	UTILITY_DAMAGE = 'utility_damage',
	UTILITY_RATING = 'utility_rating',
	WIN_STREAK = 'win_streak',
}

export enum MatchmakingRankType {
	NA = -1,
	NONE = 0,
	CSGO_COMPETITIVE = 6,
	WINGMAN = 7,
	DANGER_ZONE = 10,
	CS2_COMPETITIVE = 11,
	CS2_PER_MAP_COMPETITIVE = 12,
}

export enum Recap2023PersonalBestSkillId {
	DEATHS = 'deaths',
	ENEMIES_FLASHED = 'enemies_flashed',
	KILLS = 'kills',
	LEETIFY_RATING = 'leetify_rating',
	MOST_ACHIEVEMENTS = 'most_achievements',
	UTILITY_DAMAGE = 'utility_damage',
}

export enum Recap2023YearInCsAccomplishmentId {
	DEMO_OUTAGE = 'demo_outage',
	FIRST_CS2_GAME = 'first_cs2_game',
	HIGHEST_ADR_GAME = 'highest_adr_game',
	HIGHEST_KILLS_GAME = 'highest_kills_game',
	HIGHEST_LEETIFY_RATING_GAME = 'highest_leetify_rating_game',
	HIGHEST_RANK_GAME_CS2_PREMIER = 'highest_rank_game_cs2_premier',
	HIGHEST_RANK_GAME_CSGO_COMPETITIVE = 'highest_rank_game_csgo_competitive',
	HIGHEST_RANK_GAME_FACEIT = 'highest_rank_game_faceit',
	HIGHEST_RANK_GAME_WINGMAN = 'highest_rank_game_wingman',
	LAST_CSGO_GAME = 'last_csgo_game',
	MOST_MATCHES_PLAYED = 'most_matches_played',
	MOST_MATCHES_WON = 'most_matches_won',
}

export enum GameSkeletonReplacementDemoStatus {
	ACCEPTED = 'accepted', // demo was used for a successful import is for the match it says it is (beyond a reasonable doubt)
	AUTHENTIC = 'authentic', // demo was downloaded from Faceit directly, i.e. we know for sure it's for the game it says it is
	DRAFT = 'draft', // used to reserve an ID while the demo is still being saved
	PENDING = 'pending', // demo has not been tried yet
	REJECTED = 'rejected', // demo was tried but did not match the skeleton stats
}
