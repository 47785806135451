import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class TrackerService {
	constructor(private http: HttpClient) { }

	// TODO: Use some sort of enum here as well
	public track(name: string, data: Record<string, any> = {}): void {
		const headers = AuthService.getHeaders();

		this.http.post(`${environment.trackingApiBaseUrl}/api/track-event/${name}`, data, { headers }).subscribe();
		// this.http.post(`/api/track/${name}`, data).subscribe();
	}
}
