<div class="notifications-menu-container" [ngClass]="{ '--active': isActive }">
	<div class="notifications-status text-center" (click)="toggleDropdown()">
		<div class="import-status-icon-radar">
			@if (isActive || numberOfUnreadNotifications > 0) {
				<leetify-icon [icon]="Icon.BELL_FILLED" class="bell-icon --active"></leetify-icon>
			} @else {
				<leetify-icon [icon]="Icon.BELL" class="bell-icon"></leetify-icon>
			}
			<div *ngIf="numberOfUnreadNotifications > 0" class="notification-number-icon">
				<div class="notification-number-text"
					 [ngStyle]="{ 'font-size': numberOfUnreadNotifications < 9 ? '9px' : '8px' }">{{ numberOfUnreadNotifications > 9 ? '9+' : numberOfUnreadNotifications }}</div>
			</div>
			<div class="notification-icons-spacing">
				<div class="server-status-border" [class]="getServerStatusType()">
					<div *ngIf="serverState !== CriticalWarningStyle.SUCCESS" class="server-status-icon" [ngStyle]="{'background-image': 'url(' + serverIconPath + ')'}"></div>
				</div>
			</div>
		</div>
	</div>

	<div class="notifications-status-dropdown">
		<div class="dropdown-content text-muted">
			<div class="status-section">
				<div class="header-title">
					Notifications
				</div>
				<div class="tooltip-container">
					<div class="server-status-hover" [class]="getServerStatusType()">
						<div class="server-status-text" [class]="getServerStatusType()">
							Server status
						</div>
						<div class="server-status-icon" [ngStyle]="{'background-image': 'url(' + serverIconPath + ')'}"></div>
					</div>
					<div *ngIf="serverState === CriticalWarningStyle.SUCCESS" class="tooltip">
						<div class="status-icon status-good"></div>
						<span>No current issues</span>
					</div>
					<div *ngIf="serverState === CriticalWarningStyle.WARNING" class="tooltip">
						<div class="status-icon status-warn"></div>
						<span>Some service disruptions</span>
					</div>
					<div *ngIf="serverState === CriticalWarningStyle.DANGER" class="tooltip">
						<div class="status-icon status-error"></div>
						<span>Major server issues</span>
					</div>
				</div>
			</div>

			<div class="separator" [style]="'margin-top: 0'"></div>

			<leetify-common-critical-warning *ngFor="let warning of criticalWarnings" [warning]="warning"></leetify-common-critical-warning>

			<div class="separator"></div>

			<!--Tabs-->
			<div class="titles-tab">
				<div class="group" (click)="switchTab('follow')">
					<div class="notifications-tab"
						 [class]="isFollowRequestsTabActive ? 'clicked' : ''">Follow requests </div>
					<span class="number-badge" [class]="isFollowRequestsTabActive ? 'number-badge-selected' : ''">{{ followRequestsCount || 0 }}</span>
				</div>

				<div class="group" (click)="switchTab('social')">
					<div class="notifications-tab"
						 [class]="isSocialNotificationsTabActive ? 'clicked' : ''">Social </div>
					<span class="number-badge" [class]="isSocialNotificationsTabActive ? 'number-badge-selected' : ''">{{ socialNotificationsCount }}</span>
				</div>

				<div class="group" (click)="switchTab('all')">
					<div class="notifications-tab"
						 [class]="isAllTabActive ? 'clicked' : ''">All</div>
					<span class="number-badge" [class]="isAllTabActive ? 'number-badge-selected' : ''">{{ allNotificationsCount || 0 }}</span>

					<!-- <div class="mark-all-as-read-tab">Mark all as read</div>-->
				</div>
			</div>
			<div class="sep" [style]="'margin-top: 0'"></div>
			<div class="notifications-container">
				<div *ngIf="notifications">
					<!--no notifications-->
					<div *ngIf="isFollowRequestsTabActive">
						<div *ngIf="followRequestsCount === 0" class="notification-text no-notification">No notifications yet!</div>
						<div *ngIf="followRequestsCount === 0" class="subtitle no-notification">When you get follow requests, they'll show up here</div>
					</div>
					<div *ngIf="isSocialNotificationsTabActive">
						<div *ngIf="socialNotificationsCount === 0" class="notification-text no-notification">No notifications yet!</div>
						<div *ngIf="socialNotificationsCount === 0" class="subtitle no-notification">When you get social notifications, they'll show up here</div>
					</div>
					<div *ngIf="isAllTabActive">
						<div *ngIf="allNotificationsCount === 0" class="notification-text no-notification">No notifications yet!</div>
						<div *ngIf="allNotificationsCount === 0" class="subtitle no-notification">When you get social notifications, they'll show up here</div>
					</div>

					<div *ngFor="let socialNotification of notifications">
						<div *ngIf="isAllTabActive">
							<div *ngIf="socialNotification.data.type === 'like'">
								<div [ngClass]="wereNotificationsOpened || socialNotification.is_read ? 'notification-row' : 'notification-row unread'"
									 (click)="goToPost(socialNotification.data.game_name, socialNotification.data.match_id, socialNotification.data.receiver_id)">
									<leetify-common-avatar [leetifyUserId]="socialNotification.data.sender_id"></leetify-common-avatar>
									<div class="notification-text-container">
										<div class="in-line">
											<div class="notification-text bold"
												 [class]="isNameTooLong(socialNotification.data.nickname) ? 'ellipsis' : ''">{{ socialNotification.data.nickname }}</div>
											<div class="notification-text"> liked a match you played in!</div>
										</div>
										<div class="in-line group">
											<div class="subtitle">{{ calculateMinutesAgo(socialNotification.created_at) }}</div>
											<div class="subtitle dot"> - </div>
											<img *ngIf="socialNotification.data.game_name === 'cs'" [src]="'assets/icons/cs.svg'" class="icon subtitle" alt="cs-icon">
											<div *ngIf="socialNotification.data.game_name === 'cs'" class="subtitle">Counter Strike 2</div>
											<img *ngIf="socialNotification.data.game_name === 'league'" [src]="'assets/icons/league.svg'" class="icon subtitle" alt="league-icon">
											<div *ngIf="socialNotification.data.game_name === 'league'" class="subtitle">League of Legends</div>
										</div>
									</div>
								</div>
								<div class="sep" [style]="'margin-top: 0'"></div>
							</div>
							<div *ngIf="socialNotification.data.type === 'follow'">
								<leetify-common-follow-request
									[followRequest]="socialNotification.data"
									[isRead]="wereNotificationsOpened || socialNotification.is_read"
									[createdAt]="socialNotification.created_at">
								</leetify-common-follow-request>
								<div class="sep" [style]="'margin-top: 0'"></div>
							</div>
						</div>

						<div *ngIf="isSocialNotificationsTabActive">
							<div *ngIf="socialNotification.data.type === 'like'">
								<div
									[ngClass]="wereNotificationsOpened || socialNotification.is_read ? 'notification-row' : 'notification-row unread'"
									(click)="goToPost(socialNotification.data.game_name, socialNotification.data.match_id, socialNotification.data.receiver_id)"
								>
									<leetify-common-avatar [leetifyUserId]="socialNotification.data.sender_id"></leetify-common-avatar>

									<div class="notification-text-container">
										<div class="in-line">
											<div class="notification-text bold" [class]="isNameTooLong(socialNotification.data.nickname) ? 'ellipsis' : ''">{{ socialNotification.data.nickname }}</div>
											<div class="notification-text"> liked a match you played in!</div>
										</div>
										<div class="in-line group">
											<div class="subtitle">{{ calculateMinutesAgo(socialNotification.created_at) }}</div>
											<div class="subtitle dot"> - </div>
											<img *ngIf="socialNotification.data.game_name === 'cs'" [src]="'assets/icons/cs.svg'" class="icon subtitle" alt="cs-icon">
											<div *ngIf="socialNotification.data.game_name === 'cs'" class="subtitle">Counter Strike 2</div>
											<img *ngIf="socialNotification.data.game_name === 'league'" [src]="'assets/icons/league.svg'" class="icon subtitle" alt="league-icon">
											<div *ngIf="socialNotification.data.game_name === 'league'" class="subtitle">League of Legends</div>
										</div>
									</div>
								</div>
								<div class="sep" [style]="'margin-top: 0'"></div>
							</div>
						</div>
						<!--follow unread notifications-->
						<div *ngIf="isFollowRequestsTabActive">
							<div *ngIf="socialNotification.data.type === 'follow'">
								<leetify-common-follow-request
									[followRequest]="socialNotification.data"
									[isRead]="wereNotificationsOpened || socialNotification.is_read"
									[createdAt]="socialNotification.created_at">
								</leetify-common-follow-request>
								<div class="sep" [style]="'margin-top: 0'"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
