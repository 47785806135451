<nav>
	<ul class="sidebar-menu">
		<li class="nav-section nav-home">
			<div class="nav-inner">
				@if (homeMenu.routerLink) {
					<a [routerLink]="homeMenu.routerLink" routerLinkActive="route-active" class="menu-entry">
						<leetify-icon [icon]="homeMenu.icon" class="main-nav-icon"></leetify-icon>
						<span>{{ homeMenu.label }}</span>
					</a>
				} @else {
					<a href="{{ homeMenu.href }}" class="menu-entry">
						<leetify-icon [icon]="homeMenu.icon" class="main-nav-icon"></leetify-icon>
						<span>{{ homeMenu.label }}</span>
					</a>
				}
			</div>
		</li>


		@for (game of menuGames; track game.slug) {
			<li class="nav-section nav-game game-{{ game.slug }}" [ngClass]="{ '--expanded': game.isExpanded }">
				<div class="nav-inner">
					<button class="section-header" (click)="toggleSubMenu(game.slug)">
						<leetify-icon [icon]="game.icon" class="main-nav-icon"></leetify-icon>
						<span>{{ game.name }}</span>
						@if (game.isExpanded) {
							<leetify-icon [icon]="Icon.NAV_MINUS"></leetify-icon>
						} @else {
							<leetify-icon [icon]="Icon.NAV_CARET_DOWN"></leetify-icon>
						}
					</button>
					@if (game.isExpanded) {
						<ul class="nav-submenu">
							<leetify-expandable-vertical [isActive]="game.isExpanded" [isAriaHiddenWhenInactive]="false">
								@for (entry of game.entries; track entry.route) {
									<li class="game-submenu">
										@if (entry.routerLink) {
											<a class="user-menu-button menu-entry" queryParamsHandling="merge" [routerLink]="entry.routerLink" routerLinkActive="route-active" [routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }">
												<leetify-icon [icon]="entry.icon"></leetify-icon>
												<span>{{ entry.label }}</span>
											</a>
										} @else {
											<a class="user-menu-button menu-entry" href="{{ entry.href }}">
												<leetify-icon [icon]="entry.icon"></leetify-icon>
												<span>{{ entry.label }}</span>
											</a>
										}
									</li>
								}
							</leetify-expandable-vertical>
						</ul>
					}
				</div>
			</li>
		}
		<li class="nav-section nav-referrals">
			<div class="nav-inner">
				@if (referralMenu.routerLink) {
					<a [routerLink]="referralMenu.routerLink" routerLinkActive="route-active" class="menu-entry">
						<leetify-icon [icon]="referralMenu.icon" class="main-nav-icon"></leetify-icon>
						<span>{{ referralMenu.label }}</span>
					</a>
				} @else {
					<a href="{{ referralMenu.href }}" class="menu-entry">
						<leetify-icon [icon]="referralMenu.icon" class="main-nav-icon"></leetify-icon>
						<span>{{ referralMenu.label }}</span>
					</a>
				}
			</div>
		</li>
	</ul>

	<div class="sidebar-social">
		<a
			aria-label="Home Page"
			container="body"
			ngbTooltip="Go to Home Page"
			openDelay="300"
			href="/?redirect=no"
			tooltipClass="default-tooltip"
		>
			<leetify-icon [icon]="Icon.NAV_HOME"></leetify-icon>
		</a>

		@for (social of socialMediaLinks; track social.link) {
			<a [href]="social.link" target="_blank" [attr.aria-label]="social.title">
				<leetify-icon [icon]="social.icon"></leetify-icon>
			</a>
		}
	</div>
</nav>
