import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	PLATFORM_ID,
	SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CriticalWarning, Follow, Notification, NotificationResponse } from 'leetify-shared-utils/dto';
import { CriticalWarningsService } from 'src/components/services/critical-warnings.service';
import { environment } from 'src/environments/environment';
import { FollowService } from 'src/components/services/follow.service';
import { NotificationsService } from 'src/components/services/notifications.service';
import { User } from 'src/components/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Icon } from '../../atoms/icon/icon.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';

enum MenuType {
	SEARCH = 'search',
	NAV = 'nav',
	NOTIFICATIONS = 'notifications',
	USER = 'user',
}

@Component({
	selector: 'leetify-common-topnav',
	templateUrl: './topnav.component.html',
	styleUrls: ['./topnav.component.scss'],
})
export class CommonTopnavComponent implements OnChanges, OnDestroy, OnInit {
	@Output() protected readonly toggleNavigation = new EventEmitter<boolean | void>();
	@Input() protected navigationActive = false;

	protected readonly isBrowser: boolean;
	protected readonly csFrontendUrl = environment.csFrontendUrl;
	protected readonly homeFrontendUrl = environment.frontendRootUrl;
	protected readonly Icon = Icon;

	protected focusSearchInput: Subject<boolean> = new Subject<boolean>();
	protected focusSearchInput$ = this.focusSearchInput.asObservable();
	protected searchActive = false;
	protected user: User;

	protected proButtonClass = 'btn-primary';
	protected proButtonText = 'Get Pro';
	protected criticalWarnings: CriticalWarning[] = [];
	protected followRequests: Follow[] = [];
	protected notifications: Notification[];
	protected numberOfUnreadNotifications: number = 0;

	protected readonly ngUnsubscribe = new Subject<void>();

	protected readonly MenuType = MenuType;
	protected isOpen = false;
	protected areNotificationsOpen = false;
	protected activeMenu: MenuType | null = null;
	protected isMobile: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		protected readonly userService: UserService,
		protected readonly criticalWarningsService: CriticalWarningsService,
		protected readonly followService: FollowService,
		protected readonly notificationsService: NotificationsService,
		protected readonly breakpointObserver: BreakpointObserver,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
		this.breakpointObserver.observe(['(max-width: 668px)']).subscribe((result) => {
			this.isMobile = result.matches;
		});
	}

	protected toggleMobileNav(menu: MenuType | null) {
		if (!this.isMobile) {
			return;
		}

		const isBottomMenu = menu === MenuType.SEARCH || menu === MenuType.NAV || menu === MenuType.USER;
		const isTopMenu = menu === MenuType.NOTIFICATIONS;

		if (isTopMenu) {
			// close bottom menu when opening top menu
			this.isOpen = false;
			this.areNotificationsOpen = !this.areNotificationsOpen;
			this.activeMenu = this.areNotificationsOpen ? menu : null;
		} else if (isBottomMenu) {
			// close top menu when opening bottom menu
			this.areNotificationsOpen = false;
			if (this.activeMenu === menu) {
				// if the same bottom menu is clicked again, close it
				this.isOpen = false;
				this.activeMenu = null;
			} else {
				this.isOpen = true;
				this.activeMenu = menu;
			}
		} else {
			// close everything when menu is null
			this.isOpen = false;
			this.areNotificationsOpen = false;
			this.activeMenu = null;
		}

		// if search is active and switching to a different tab, close search
		if (menu !== MenuType.SEARCH && this.searchActive) {
			this.toggleSearch(null, false);
		}

		// if nav is active and switching to a different tab, close nav
		if (menu !== MenuType.NAV && this.navigationActive) {
			this.toggleNavigation.emit();
		}

		// remove scroll from background when menu is opened after close animation
		setTimeout(() => {
			this.removeScrollInBackground(this.isOpen || this.areNotificationsOpen);
		}, 300);
	}

	protected removeScrollInBackground(toRemove: boolean) {
		document.body.style.overflow = toRemove ? 'hidden' : '';
	}

	public toggleNav(e: MouseEvent, mobileFlag?: boolean): void {
		e.preventDefault();

		if (mobileFlag) {
			this.toggleMobileNav(MenuType.NAV);
		}

		this.toggleNavigation.emit();
	}

	public toggleSearch(e?: MouseEvent, value?: boolean, mobileFlag?: boolean): void {
		if (e) e.preventDefault();

		if (mobileFlag) {
			this.toggleMobileNav(MenuType.SEARCH);
		}

		this.searchActive = value === undefined ? !this.searchActive : value;

		if (this.searchActive) {
			document.body.classList.add('--mobile-search-active');
			this.toggleNavigation.emit(false);
			this.focusSearchInput.next(true);
		} else {
			document.body.classList.remove('--mobile-search-active');
		}
	}

	private setProButton(user: User): void {
		if (!user) return;

		this.proButtonClass = 'btn-primary';
		this.proButtonText = 'Get Pro';

		if (user.isProPlan) {
			this.proButtonClass = 'btn-primary';
			this.proButtonText = 'My Pro';
		}

		if (user.isCollector) {
			this.proButtonClass = 'btn-founder';
		}
	}

	public setUser(user: User): void {
		this.user = user;

		this.setProButton(user);

		if (user) {
			this.criticalWarningsService.criticalWarnings$
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((criticalWarnings) => this.handleCriticalWarnings(criticalWarnings));

			this.criticalWarningsService.reloadWarnings();

			this.followService.followRequests$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((follow) => {
				this.handleFollow(follow.users);
			});

			this.followService.reloadUserFollowRequests();

			this.notificationsService.notifications$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((notification) => {
				this.handleNotifications(notification);
			});

			this.notificationsService.reloadUserNotifications();
		}
	}

	protected handleCriticalWarnings(warnings: CriticalWarning[]) {
		this.criticalWarnings = warnings;
	}

	protected handleFollow(follows: Follow[]) {
		this.followRequests = follows;
	}

	protected handleNotifications(notifications: NotificationResponse) {
		if (notifications) {
			this.notifications = [...(notifications.unreadNotificationsResponse || []), ...(notifications.readNotificationsResponse || [])];

			this.numberOfUnreadNotifications = notifications.unreadNotificationsResponse.length;
		}
	}

	public ngOnInit(): void {
		if (!this.isBrowser) return;

		this.userService.reloadUser();
		this.setUser(this.userService.user);
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.setUser(user));
	}

	public ngOnChanges(changes: SimpleChanges): void {
		// close mobile search when activating mobile nav
		if (changes.navigationActive && changes.navigationActive.currentValue) {
			this.searchActive = false;
		}

		if (!this.navigationActive && (this.activeMenu === null || this.activeMenu === MenuType.NAV)) {
			this.toggleMobileNav(null);
		}
	}

	public ngOnDestroy(): void {
		if (!this.isBrowser) return;

		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		document.body.style.overflow = '';
	}
}
