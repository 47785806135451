import { firstValueFrom, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// TODO move to shared submodule
export interface UserPreferencesDTO {
	isAnimatedCosmeticsEnabled?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class UserPreferencesService {
	public readonly userPreferences$ = new Subject<UserPreferencesDTO>();

	public userPreferences: UserPreferencesDTO;

	public constructor(
		protected readonly http: HttpClient,
	) {
		//
	}

	public async reloadUserPreferences(): Promise<UserPreferencesDTO> {
		const preferences = await firstValueFrom(this.http.get<UserPreferencesDTO>(`${environment.userApiUrl}/user-preferences/v1`));

		this.userPreferences = preferences;
		this.userPreferences$.next(preferences);

		return preferences;
	}

	public async patchUserPreferences(patch: Partial<UserPreferencesDTO>): Promise<UserPreferencesDTO> {
		const patchedPreferences = await firstValueFrom(this.http.patch<UserPreferencesDTO>(`${environment.userApiUrl}/user-preferences/v1`, patch));

		this.userPreferences = patchedPreferences;
		this.userPreferences$.next(patchedPreferences);

		return patchedPreferences;
	}
}
