import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import moment from 'moment';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { StorageKey } from './storage.service';
import { TrackerService } from './tracker.service';

interface AffiliateMapping {
	name: string;
	affiliateReferralCode: string;
}

@Injectable({
	providedIn: 'root',
})
export class AffiliateService {

	private affiliateMap$ = new Subject<AffiliateMapping[]>();
	private affiliateMap: AffiliateMapping[] = [];

	public constructor(
		protected readonly http: HttpClient,
		protected readonly localStorageService: LocalStorageService,
		protected readonly trackerService: TrackerService,
	) {
		this.affiliateMap$.subscribe((affiliates) => (this.affiliateMap = affiliates));
	}

	public getReferralCodeFromAffiliateCode(affiliateCode: string): string {
		const affiliateMapping = this.affiliateMap.find((mapping) => mapping.name.toLowerCase() === affiliateCode.toLowerCase());
		if (affiliateMapping) {
			return affiliateMapping.affiliateReferralCode;
		}
		return null;
	}

	public async getAffiliate(affiliateCode: string): Promise<void> {
		try {
			const affiliate = await this.http
				.get<{ name: string; affiliateReferralCode: string }>(`/api/affiliate/${affiliateCode}`)
				.toPromise();

			if (affiliate) {
				const formattedAffiliate = {
					name: affiliate.name,
					affiliateReferralCode: affiliate.affiliateReferralCode,
				};

				this.affiliateMap$.next([formattedAffiliate]);
			} else {
				this.affiliateMap$.next([]);
			}
		} catch (error) {
			console.error('Error fetching affiliate:', error);
			this.affiliateMap$.next([]);
		}
	}

	public async saveReferralCode(referrerUserId: string): Promise<void> {
		this.localStorageService.saveValue(StorageKey.REFERRAL_CODE, referrerUserId);
		this.localStorageService.saveValue(StorageKey.REFERRAL_EXPIRES_AT, moment().add(14, 'days').toJSON());
	}

	public getSavedReferralCode(): string {
		const referralCode = this.localStorageService.getValue<string>(StorageKey.REFERRAL_CODE);
		if (!referralCode) return;

		// const expiresAt = this.localStorageService.getValue(StorageKey.REFERRAL_EXPIRES_AT);
		// if (!expiresAt) return;

		// if (moment().isBefore(expiresAt)) return referralCode;
		return referralCode;
	}
}
