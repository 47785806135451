export enum AvatarStyle {
	BRONZE = 'bronze',
	DEFAULT = 'default',
	FOUNDER = 'founder',
	GOLD = 'gold',
	PRO = 'pro',
	SILVER = 'silver',
	STAFF = 'staff',
}

export enum CosmeticId {
	NONE = 'none',

	ANCIENT = 'ancient',
	ANUBIS = 'anubis',
	ASTRAL = 'astral',
	CROSSHAIR = 'crosshair',
	FLORAL = 'floral',
	RAINBOW = 'rainbow',
	SMOKE = 'smoke',
}

export enum CosmeticImageType {
	AVATAR_BORDER = 'avatar-border',
	BANNER_BORDER_FULL = 'banner-border-full',
	BANNER_BORDER_PREVIEW = 'banner-border-preview',
	MINI_PROFILE_BORDER_FULL = 'mini-profile-border-full',
}

export enum CosmeticImageUrl {
	ANIMATED_LOOPING_HQ_AVIF = 'animated-looping-cq23.avif',
	ANIMATED_LOOPING_LOSSLESS_AVIF = 'animated-looping-lossless.avif',
	ANIMATED_LOOPING_LOSSLESS_WEBP = 'animated-looping-lossless.webp',
	STATIC_HQ_AVIF = 'static-q90-s0.avif',
	STATIC_HQ_WEBP = 'static-q95-m6.webp',
	STATIC_LOSSLESS_AVIF = 'static-lossless.avif',
	STATIC_LOSSLESS_PNG = 'static-lossless.png',
	STATIC_LOSSLESS_WEBP = 'static-lossless.webp',
}
