<div #avatarHoverTarget class="user-menu-container" [ngClass]="{ active: isMenuActive }">
	<div class="user" (click)="toggleDropdown()">
		<leetify-common-avatar
			[hoverTarget]="avatarHoverTarget"
			[isBorderAnimated]="true"
			[isBorderOnlyAnimatedOnHover]="true"
			[leetifyUserId]="user?.id"
			[showCosmetics]="true"
			[showStyle]="true"
		></leetify-common-avatar>

		<div class="text-truncate">
			{{ displayName }}
		</div>

		<div class="spacer"></div>

		<div class="caret" [ngClass]="{ '--rotate-180deg': isMenuActive }">
			<i class="fas fa-caret-down ml-2"></i>
		</div>
	</div>

	<div class="user-dropdown">
		<div class="dropdown-content">
			<div class="user-text nickname" (touchstart)="goToProfile()">
				<leetify-common-avatar
					[isBorderAnimated]="isMenuActive"
					[leetifyUserId]="user?.id"
					[showCosmetics]="true"
					[showStyle]="true"
				></leetify-common-avatar>
				{{ user.nickname }}
			</div>

			<a href="{{ csFrontendUrl }}/profile/{{ user.steam64Id }}" class="user-menu-button" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
				<i class="fa fa-user user-menu-icon"></i>
				<span>Profile</span>
			</a>

			@if (availableProgressReports?.length) {
				<button class="user-menu-button --progress-reports-toggle" (click)="toggleProgressReportsSubmenu()">
					<leetify-icon [icon]="Icon.PIE_CHART" class="user-menu-icon --pie-chart"></leetify-icon>
					<span>Progress Reports</span>
					<leetify-icon [icon]="Icon.CARET_DOWN" class="caret" [ngClass]="{ '--rotate-180deg': isProgressReportsSubmenuExpanded }"></leetify-icon>
				</button>

				<leetify-expandable-vertical [isActive]="isProgressReportsSubmenuExpanded" [isAriaHiddenWhenInactive]="false">
					<div class="progress-reports-menu">
						<div *ngFor="let group of availableProgressReports">
							<div class="heading">{{ group.heading }}</div>
							@for (report of group.reports; track $index) {
								@if (currentApp === 'cs') {
									<a [routerLink]="report.routerLink">{{ report.title }}</a>
								} @else {
									<a href="{{ report.href }}">{{ report.title }}</a>
								}
							}
						</div>
					</div>
				</leetify-expandable-vertical>
			}

			@if (availableAnnualRecaps?.length) {
				<button class="user-menu-button --progress-reports-toggle" (click)="toggleAnnualRecapsSubmenu()">
					<leetify-icon [icon]="Icon.PARTY_POPPER" class="user-menu-icon" style="font-size: 1.25rem"></leetify-icon>
					<span>Annual Recaps</span>
					<leetify-icon [icon]="Icon.CARET_DOWN" class="caret" [ngClass]="{ '--rotate-180deg': isAnnualRecapsSubmenuExpanded }"></leetify-icon>
				</button>

				<leetify-expandable-vertical [isActive]="isAnnualRecapsSubmenuExpanded" [isAriaHiddenWhenInactive]="false">
					<div class="progress-reports-menu" *ngIf="currentApp == 'cs'">
						<a *ngFor="let report of availableAnnualRecaps" [routerLink]="report.routerLink">{{ report.title }}</a>
					</div>
					<div class="progress-reports-menu" *ngIf="currentApp != 'cs'">
						<a *ngFor="let report of availableAnnualRecaps" href="{{ report.href }}">{{ report.title }}</a>
					</div>
				</leetify-expandable-vertical>
			}

			<a href="{{ homeFrontendUrl }}/account" class="user-menu-button" routerLinkActive="active">
				<i class="fa fa-cog user-menu-icon"></i>
				<span>Account Settings</span>
			</a>

			<a href="{{ csFrontendUrl }}/data-sources" class="user-menu-button" routerLinkActive="active">
				<i class="leet-datasources user-menu-icon"></i>
				<span>Data Sources</span>
			</a>

			<a href="{{ csFrontendUrl }}/support" class="user-menu-button" routerLinkActive="active">
				<i class="leet-support user-menu-icon"></i>
				<span>Support</span>
			</a>

			<div class="user-menu-button center">
				<a class="discord-button" href="https://discord.gg/UNygC8BAVg" target="_blank">
					<i class="fab fa-discord user-menu-icon"></i>
					<span>Join our Discord</span>
				</a>
			</div>

			<div class="mobile">
				<div class="user-menu-button center pro mobile">
					<a
						[href]="PATH_PRO"
						class="btn"
						[ngClass]="proButtonClass"
						container="body"
						[ngbTooltip]="user.isCollector ? 'Thanks for being a Pioneer!' : null"
						openDelay="600"
						placement="bottom"
						tooltipClass="default-tooltip">
						{{ proButtonText }}
					</a>
				</div>
			</div>

			<button class="user-menu-button logout-button" (click)="logout()">
				<i class="fa fa-sign-out-alt user-menu-icon"></i>
				<span>Logout</span>
			</button>
		</div>
	</div>
</div>
