export type ZScoreDeviationStep = -2 | -1 | 0 | 1 | 2;

export class ZScoreHelper {
	public static readonly zScorePercentile = {
		_70: 0.524,
		_90: 1.28155,
	};

	public static getZScore(userStat: number, statAvg: number, statStd: number, matchesCount: number, isInversed: boolean): number {
		let zScore = (userStat - statAvg) / statStd;
		if (isInversed) zScore *= -1;

		return zScore * Math.sqrt(matchesCount);
	}

	public static valueFromZScore(zScore: number, avg: number, std: number, significance = 2): number {
		return Number(((zScore * std) + avg).toFixed(significance));
	}

	public static getZScoreDeviationStep(userStat: number, statAvg: number, statStd: number, matchesCount: number, isInversed: boolean): ZScoreDeviationStep | null {
		const zScore = this.getZScore(userStat, statAvg, statStd, matchesCount, isInversed);

		if (zScore < -ZScoreHelper.zScorePercentile._90) return -2;
		if (zScore < -ZScoreHelper.zScorePercentile._70) return -1;
		if (zScore < ZScoreHelper.zScorePercentile._70) return 0;
		if (zScore < ZScoreHelper.zScorePercentile._90) return 1;
		if (zScore >= ZScoreHelper.zScorePercentile._90) return 2;

		return null;
	}

	public static getZScoreDeviationStepLabel(userStat: number, statAvg: number, statStd: number, matchesCount: number, isInversed: boolean): string {
		return ZScoreHelper.getZScoreLabel(
			ZScoreHelper.getZScoreDeviationStep(userStat, statAvg, statStd, matchesCount, isInversed),
		);
	}

	public static getZScoreLabel(zScoreDeviation: ZScoreDeviationStep): string {
		switch (zScoreDeviation) {
			case -2: return 'Poor';
			case -1: return 'Subpar';
			case 0: return 'Average';
			case 1: return 'Good';
			case 2: return 'Great';
		}
	}

	public static getZScoreTextColorClass(zScoreDeviation: ZScoreDeviationStep): string {
		switch (zScoreDeviation) {
			case -2: return 'text-poor';
			case -1: return 'text-subpar';
			case 0: return 'text-average';
			case 1: return 'text-good';
			case 2: return 'text-great';
		}
	}

	public static getScoreCircleOutlineColorClass(zScoreDeviation: ZScoreDeviationStep): string {
		switch (zScoreDeviation) {
			case -2: return 'poor';
			case -1: return 'subpar';
			case 0: return 'average';
			case 1: return 'good';
			case 2: return 'great';
		}
	}

	public static getPercentileThresholds(avg: number, std: number): number[] {
		// lookup table for the z-score that belongs to the 1st, 2nd, ..., 99th percentile
		const zScorePercentiles = [-2.3263, -2.0537, -1.8808, -1.7507, -1.6449, -1.5548, -1.4758, -1.4051, -1.3408, -1.28155, -1.2265, -1.175, -1.1264, -1.0803, -1.0364, -0.9945, -0.9542, -0.9154, -0.8779, -0.8416, -0.8064, -0.7722, -0.7388, -0.7063, -0.6745, -0.6433, -0.6128, -0.5828, -0.5534, -0.524, -0.4959, -0.4677, -0.4399, -0.4125, -0.3853, -0.3585, -0.3319, -0.3055, -0.2793, -0.2533, -0.2275, -0.2019, -0.1764, -0.151, -0.1257, -0.1004, -0.0753, -0.0502, -0.0251, 0, 0.0251, 0.0502, 0.0753, 0.1004, 0.1257, 0.151, 0.1764, 0.2019, 0.2275, 0.2533, 0.2793, 0.3055, 0.3319, 0.3585, 0.3853, 0.4125, 0.4399, 0.4677, 0.4959, 0.524, 0.5534, 0.5828, 0.6128, 0.6433, 0.6745, 0.7063, 0.7388, 0.7722, 0.8064, 0.8416, 0.8779, 0.9154, 0.9542, 0.9945, 1.0364, 1.0803, 1.1264, 1.175, 1.2265, 1.28155, 1.3408, 1.4051, 1.4758, 1.5548, 1.6449, 1.7507, 1.8808, 2.0537, 2.3263];

		return zScorePercentiles.map((zScore) => avg + zScore * std);
	}
}
